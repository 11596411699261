export default function getMainUrlOfPath(pathname: string): string {
    // temporary use hardcoded URL until Teams app manifest updated with the real URL
    return `https://fuehrungskompass.thyssenkrupp-steel.com${pathname}`;

    // // Get the current URL
    // const currentUrl = window.location.href;

    // // Split the URL into parts
    // const urlParts = currentUrl.split('/');

    // // Get the protocol and host (e.g., "https://example.com")
    // const protocolAndHost = urlParts.slice(0, 3).join('/');

    // // Combine the protocol, host, and the provided pathname
    // const mainUrl = `${protocolAndHost}${pathname}`;

    // return mainUrl;
}