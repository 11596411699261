export function VideoDescription(props: { title: string; children?: React.ReactNode; className?: string; }) {
    return (
        <div className={`px-4 py-3 text-white bg-tk-brand ${props.className}`}>
            <span className="block pb-1 text-base font-tk-medium">{props.title}</span>
            <span className="block text-sm leading-relaxed">
                {props.children}
            </span>
        </div>
    );
}
