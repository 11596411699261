import { useMsal } from "@azure/msal-react";
import config from "../components/config";
import img_logo from "../images/Logo.png";
import img_arrow from "../images/arrow.png";
import img_kompass from "../images/kompass.png";

const LoginPage = () => {
    const { instance } = useMsal();

    const initializeSignIn = async () => {

        const protocolAndHost = window.location.href.split('/').slice(0, 3).join('/');

        try {
            await instance.loginRedirect({
                scopes: config.scopes,
                redirectUri: protocolAndHost

            })
        } catch (error) {

            instance.loginPopup({
                scopes: config.scopes,
                redirectUri: protocolAndHost
            })
                .then((response) => { })
                .catch((error) => {
                    // alert(error);
                });

        }
    };

    return (
        <div className="m-auto bg-tk-brand">
            <div className="flex flex-col items-center justify-start w-full h-full pt-20">
                <img src={img_kompass} alt="kompass" />
                <h1 className="mb-10 text-2xl leading-snug text-center text-white lg:text-4xl font-tk-regular">
                    Herzlich Willkommen bei Ihrem<br />
                    Führungskompass.
                </h1>
                <button onClick={initializeSignIn}
                    className="px-6 py-3 text-lg leading-none text-white border border-white rounded-full font-tk-medium bg-tk-brand ">
                    Microsoft Login
                    <img src={img_arrow} alt="arrow" className="inline-block w-5 ml-2" />
                </button>
                <img src={img_logo} alt="logo" className="w-24 mt-20 " />
            </div>
        </div>
    );
};

export default LoginPage;