import { useState } from 'react';
import { Link } from 'react-router-dom';
import BasicLayout from '../components/BasicLayout';
import Breadcrumb from '../components/Breadcrumb';
import ContainerCenter from "../components/ContainerCenter";
import FührungsSideLinks from '../components/FührungsSideLinks';
import HeroImage from '../components/HeroImage';
import { RelevanteDokumente } from '../components/RelevanteDokumente';
import Section from "../components/Section";
import TKText from "../components/TKText";
import img_FLK_Dialoge from '../images/FLK-Dialog/FLK_Dialoge.jpg';
import img_Hero from '../images/FLK-Dialog/hero_bg.jpg';
import img_Kollage_Intranet from '../images/FuehrungsUndLeistungskultur/Kollage_Intranet.png';
import img_arrow from "../images/menu_arrow.svg";
import { RelevantDocument } from '../types/RelevantDocument';
import links from './links';


export const Documents: RelevantDocument[] = [
    {
        title: "DOKU_1. FLK-Dialog_Führung in hybriden Arbeitswelten_Mai 23",
        size: "9,8 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=eb8a316f-b878-4cb5-97c0-77de7cde397d"
    }
];


export default function FLKDialogPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <Breadcrumb items={[
                    <Link to={links["Führungsverständnis stärken"]}>Führungsverständnis stärken</Link>,
                    <Link to={links["Führungs und Leistungskultur"]}>Führungs- und Leistungskultur</Link>,
                    <Link to={links["FLK-Dialog"]}>FLK-Dialog</Link>,
                ]} />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <TextSection />
                        <CollapseFuehren />
                        <CollapseDialog />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                        <FührungsSideLinks />
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="FLK Dialog">
                <HeroImage.Title>FLK Dialog</HeroImage.Title>
            </HeroImage>
        </Section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Unser Austauschangebot mit neuen Impulsen
                und Lösungsansätzen zu Herausforderungen im
                Führungsalltag
            </TKText.Header>
            <TKText.SubHeader>
                Sie stehen vor komplexen Herausforderungen in Ihrer Führungsaufgabe und
                wünschen sich Austausch mit Gleichgesinnten und neue Ideen für
                Lösungsansätze?<br />
                Dann nehmen Sie an einem unserer FLK-Dialoge teil!
            </TKText.SubHeader>
            <TKText.Body>
                Wir sind mitten in der Transformation. Führungskräfte sind dabei insbesondere gefordert - sowohl in der Erreichung
                operativer und strategischer Bereichsziele als auch in ihrer Führungsrolle. Gerade hier helfen andere Perspektiven, neue
                Ansätze sowie der Austausch mit Kolleg:innen, die vor ähnlichen Herausforderungen stehen, gut weiter. Doch gerade dann
                fehlt dazu meist die Zeit.
            </TKText.Body>
            <TKText.Body>
                Der FLK-Dialog zielt daher darauf ab, einen Raum und einen Zeitrahmen genau dafür zu bieten: für offenen Austausch und
                Vernetzung untereinander, für Impulse und Lösungsansätze zu altbekannten oder ganz neuen Führungsherausforderungen.
                Aus den Bedarfen der Organisation wird jeweils ein aktuelles Führungsthema als Fokusthema eines Veranstaltungszyklus'
                abgeleitet. Das Konzept kombiniert dabei eine Präsenzveranstaltung als Möglichkeit zum Kennenlernen und Vernetzen mit
                anschließend vertiefenden Online-Angeboten in Form von Learning Nuggets. Für einen intensiven Austausch untereinander
                arbeiten wird dabei jeweils mit max. 50 Teilnehmenden zusammen.
            </TKText.Body>
            <p className='mb-8 font-bold text-tk-text-grey'>
                Haben wir Ihr Interesse geweckt? Dann schreiben Sie eine Mail an: <br />
                <a className="text-tk-brand"
                    href="mailto:leadershipcommunication.steel@steeleurope.com">
                    leadershipcommunication.steel@steeleurope.com
                </a>
            </p>
            <div className='mt-4 mb-10'>
                <a href={img_FLK_Dialoge} target='_blank' rel="noreferrer">
                    <img src={img_FLK_Dialoge} alt="Unser Zielbild" className="w-full border border-tk-brand" />
                </a>
            </div>

        </Section >
    )
}

function CollapseFuehren() {
    return (
        <Collapse title={"1. FLK-Dialog: Thema „Führen in hybriden Arbeitswelten\""}>
            <TKText.Body>
                Wir alle haben unsere Erfahrungen mit dem Wechsel zwischen mobiler Arbeit,
                digitaler Zusammenarbeit und Arbeiten vor Ort in Betrieb und Büro gemacht.
            </TKText.Body>
            <TKText.Body>
                Vieles funktioniert gut, manches vielleicht weniger. Was macht es schwierig, was können wir verändern und gestalten?
                Genau um diese Fragestellung dreht sich alles in unserer ersten Veranstaltungsreihe im Rahmen des FLK-Dialogs zu
                „Führen in hybriden Arbeitswelten".
            </TKText.Body>
            <TKText.Body>
                <span className='font-bold'>Am 5. Mai 2023 haben sich interessierte Führungskräfte aus dem Steel Leadership Team (SLT) und
                    Teamleiter:innen aus dem Führungskräfte-Talentpool im Bildungszentrum in Duisburg mit Markus Grolms dazu
                    intensiv ausgetauscht.</span> In Kleingruppen wurden die größten Herausforderungen aus dem Führungsalltag identifiziert und
                Lösungsansätze als <br />
                <a href='https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=eb8a316f-b878-4cb5-97c0-77de7cde397d' target='_blank' rel="noreferrer" >Quick Wins</a> bereits untereinander geteilt.
            </TKText.Body>

            <div className='mt-4 mb-10'>
                <a href={img_Kollage_Intranet} target='_blank' rel="noreferrer">
                    <img src={img_Kollage_Intranet} alt="Unser Zielbild" className="w-full" />
                </a>
            </div>

            <TKText.SubHeader>
                <span className='text-tk-brand'>
                    Wie folgt die unsere vier vertiefenden Learning-Nuggets dazu:
                </span>
            </TKText.SubHeader>
            <TKText.Body className='px-4'>
                <ul className='font-bold list-decimal list-outside marker:text-tk-brand '>
                    <li className='px-4 mb-2'>21. Juni 2023: Mein neuer Job - „Mein neuer Job: Beziehungsmanager:in!" - Partizipation und Teamgeist in
                        hybriden Arbeitswelten stärken</li>
                    <li className='px-4 mb-2'>12. Juli: Thema folgt in Kürze</li>
                    <li className='px-4 mb-2'>29. September: Thema folgt in Kürze</li>
                </ul>
            </TKText.Body>
            <RelevanteDokumente docs={Documents} />
        </Collapse>
    )
}

function CollapseDialog() {
    return (
        <Collapse title={"2. FLK-Dialog"}>
            <TKText.SubHeader>
                Comming soon (September 2023)
            </TKText.SubHeader>
        </Collapse>
    )
}

function Collapse(props: { children: React.ReactNode, title: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    return (
        <Section>
            <div className='relative cursor-pointer' onClick={e => setOpen(!open)}>
                <h4 className='pb-1 text-2xl font-bold cursor-pointer text-tk-brand'>{props.title}</h4>
                <img className={`absolute right-0 inline-block w-5 ml-1 transition rotate-180 top-2`} src={img_arrow} alt="" />
            </div>
            {open && props.children}
        </Section>
    )
}
