import { Link } from "react-router-dom";
import IconExternalLink from "./IconExternalLink";

export function SideLinkItem(props: { url: string; children: string; className?: string; external?: boolean; }) {
    return (
        <Link
            target={props.external ? "_blank" : "_self"}
            to={props.url}
            className={`flex items-baseline w-full py-3 border-b border-solid text-tk-brand border-b-tk-grey ${props.className}`} rel="noreferrer">
            {props.children}
            {props.external && <IconExternalLink className="inline w-3 ml-4 fill-tk-brand" />}
        </Link>);
}
