import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroIchMöchte from "../components/HeroIchMöchte";
import Section from "../components/Section";
import Tile from "../components/Tile";
import VideoComponent from "../components/VideoComponent";
import makeTileDataToSearchItem from "../helpers/makeTileDataToSearchItem";
import img_preview from "../images/Mediathek/das_geheimnis_der_motivation.png";
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";
import { TileData } from "../types/TileData";
import img_01 from "./../images/FuehrungsUndLeistungskultur/hero_bg.jpg";
import img_Hero from './../images/FuehrungsverstaendnisStaerken/header_bg.svg';
import img_02 from "./../images/FuehrungsverstaendnisStaerken/img_02.png";
import img_03 from "./../images/FuehrungsverstaendnisStaerken/img_03.jpg";
import img_04 from "./../images/FuehrungsverstaendnisStaerken/tk_flags_LGBTQ.jpeg";

import links from "./links";

const TilesData: TileData[] = [
    {
        title: "Meine Rolle als Führungskraft",
        link: links["Meine Rolle als Führungskraft"],
        external: false,
        description: `
            Als Führungskraft spielen Sie eine zentrale Rolle, Ihre Aufgabe
            besteht darin, ein Team oder eine Abteilung erfolgreich zu leiten
            und zu motivieren, um gemeinsame Ziele zu erreichen. Als
            Führungskraft sind Sie ebenso Mentor, ein Problemlöser und ein
            Bindeglied zwischen der Unternehmensleitung und Ihren
            Teammitgliedem.
        `,
    },
    {
        title: "Führungsinstrumente",
        link: links["Führungsinstrumente"],
        external: false,
        description: `
            Ihr Kompass für strukturierte und differenzierte Vorgehensweisen in der Mitarbeiterführung. Sie möchten Ihren Mitarbeitern ein klares Feedback geben, Leistungen beurteilen und Entwicklungsmöglichkeiten ableiten. Hier finden Sie die passenden Instrumente.
        `,
    },
    {
        title: "Führungs- & Leistungskultur",
        link: links["Führungs und Leistungskultur"],
        external: false,
        description: `
            Unser Zielbild der Führungs- und Leistungskultur gibt eine
            gemeinsame Orientierung zur Ausrichtung unserer Führung und
            der Gestaltung unserer Zusammenarbeit in einer zunehmend
            komplexen und volatilen Umwelt.
            `,
    },
    {
        title: "Das Entwicklungsportal",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal",
        external: true,
        description: `
        Unser Entwicklungsportal ist ein wertvolles Werkzeug zur persönlichen und teaminternen Entwicklung. Es bietet Zugang zu starken Instrumenten und hilfreichen Ideensammlungen, mit denen Sie Ihre Fähigkeiten und (Führungs-)kompetenzen kontinuierlich verbessern können.
        `
    },
    {
        title: "Vielfalt als Chance – Vielfalt leben",
        link: links['LGBTQ+'],
        external: false,
        description: `
        Diversity ist kein Trend, sondern ein unverzichtbares Element für den Erfolg unseres Unternehmens. Sie als Führungskraft tragen dazu bei, dass Vielfalt im Team geschätzt und gefördert wird. Denn diverse Perspektiven bringen innovative Lösungen hervor, stärken die Resilienz und erhöhen die Leistungsfähigkeit. Indem wir die Einzigartigkeit jedes Einzelnen anerkennen und wertschätzen, schaffen wir eine Kultur der Inklusion, die die Zukunft unseres Unternehmens prägt.
        `
    }
];

export const SearchLinks: SearchItem[] = [
    {
        title: "Führungsverständnis stärken",
        link: links["Führungsverständnis stärken"],
        description: "Ein starkes Führungsverständnis ermöglicht es, klare Ziele zu setzen, strategische Entscheidungen zu treffen und Teams effektiv zu organisieren.",
        type: SearchItemType.InternalLink
    },
    ...TilesData.map(tileData => makeTileDataToSearchItem(tileData))
];


export default function FührungsverständnisStärkenPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <HeroImage />
            <ContainerCenter>
                <Tiles />
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <Section>
            <HeroIchMöchte image={img_Hero} bgClassName="bg-tk-brand">
                <HeroIchMöchte.Headline className="text-white">Ich möchte: Führungsverständnis stärken</HeroIchMöchte.Headline>
                <HeroIchMöchte.Header className="text-white">
                    Ein starkes Führungsverständnis ermöglicht es,<br />
                    klare Ziele zu setzen, strategische Entscheidungen<br />
                    zu treffen und Teams effektiv zu organisieren.
                </HeroIchMöchte.Header>
                <HeroIchMöchte.Body className="text-white">
                    Führungskräfte spielen eine wesentliche Rolle dabei, Teams zu motivieren, Zielvorgaben zu<br />
                    erreichen und die Vision der Organisation voranzutreiben. Um jedoch effektive Führung zu<br />
                    gewährleisten, müssen Führungskräfte kontinuierlich ihr Führungsverständnis stärken.
                </HeroIchMöchte.Body>
            </HeroIchMöchte>
        </Section>
    )
}

function Tiles() {
    return (
        <Section>
            <div className="grid grid-cols-12 gap-0">
                <div className="col-span-12 lg:col-span-6">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=9f5d0a60-8e47-4539-a450-f33e34568c5d&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={img_preview}
                        height={356}
                        title="Das Geheimnis der Motivation"
                        subtitle="Michael Paschen, Geschäftsführer Profil M GmbH"
                    />
                </div>
                <Tile double className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[0].link}
                    linkTo2={links["Ansprechpartner"]}
                    linkTo2Text={"Ansprechpartner"}
                    linkTo2Border={false}
                    title={TilesData[0].title}>
                    {TilesData[0].description}
                </Tile>
                {/*  */}
                <Tile double className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[1].link}
                    title={TilesData[1].title}
                >
                    {TilesData[1].description}
                </Tile>
                <Tile double image={img_02} />
                {/*  */}
                <Tile double image={img_01} />
                <Tile double className="bg-tk-blue-dark hover:bg-tk-blue-dark-hover"
                    linkTo={TilesData[2].link}
                    title={TilesData[2].title}
                >
                    {TilesData[2].description}
                </Tile>
                <Tile double className="bg-tk-purple hover:bg-tk-purple-hover"
                    linkTo={TilesData[3].link}
                    external={TilesData[3].external}
                    title={TilesData[3].title}>
                    {TilesData[3].description}
                </Tile>
                <Tile double image={img_03} />
                <Tile double image={img_04} />
                <Tile double className="bg-tk-orange hover:bg-tk-orange-hover"
                    linkTo={TilesData[4].link}
                    external={TilesData[4].external}
                    title={TilesData[4].title}>
                    {TilesData[4].description}
                </Tile>
            </div>
        </Section>
    );
}
