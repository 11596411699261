import { Link } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroImage from "../components/HeroImage";
import IconExternalLink from "../components/IconExternalLink";
import IconPodcast from "../components/IconPodcast";
import { RelevanteDokumente } from "../components/RelevanteDokumente";
import Section from "../components/Section";
import SmallTile from "../components/SmallTile";
import TKText from "../components/TKText";
import VideoComponent from "../components/VideoComponent";
import { VideoDescription } from "../components/VideoDescription";
import video_preview_01 from '../images/Mediathek/Wie_Potenzial_zur_Staerke_wird.png';
import img_preview from "../images/Mediathek/lernen_und_selbstreflexion_-_warum_menschen_oft_an_ihren_staerken_scheitern_und_wie_sie_das_verhindern.png";
import img_Hero from '../images/MichEntwickeln/header_bg.jpg';
import video_preview_02 from '../images/MitarbeitendeEntwickeln/video_preview_02.png';
import { RelevantDocument } from "../types/RelevantDocument";
import img_workday from "./../images/Entwickeln/workday_icon_white.png";

export const Documents: RelevantDocument[] = [
    {
        title: "Impulsvortrag Lead Yourself",
        size: "1,3 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=38b6ccc4-c348-4340-ab30-e6b441b9b97c"
    },
    {
        title: "Selbstreflexion als Kompetenz",
        size: "2,1 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=abf5a8f3-e5b5-41e2-adf8-589737701749"
    },
];

export default function MichEntwickelnPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <TextSection />
                <VideoSection />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <SmallTiles />
                        <RelevanteDokumente docs={[Documents[1]]} />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="Mich entwickeln">
                <HeroImage.Title>Mich entwickeln</HeroImage.Title>
            </HeroImage>
        </Section>
    )
}

function TextSection() {
    return (
        <Section className="p-4 lg:p-0">
            <TKText.Header>
                Die persönliche Entwicklung ist ein
                kontinuierlicher Prozess des Wachstums,
                Lernens und Wandels.
            </TKText.Header>
            <TKText.SubHeader>
                Es geht darum, bewusst an sich selbst zu arbeiten, um das eigene Potenzial voll
                auszuschöpfen und ein sinnerfülltes (Arbeits-)leben zu führen. Die persönliche
                Entwicklung beinhaltet sowohl die Verbesserung von Fähigkeiten und
                Kompetenzen, als auch die Weiterentwicklung der emotionalen Intelligenz, des
                Selbstbewusstseins und der mentalen Stärke.
            </TKText.SubHeader>
        </Section >
    )
}

function VideoSection() {
    return (
        <Section>
            <div className="grid grid-cols-1 gap-5 p-4 lg:p-0 lg:grid-cols-3">
                <div className="col-span-1">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=59f32513-7dbe-4734-813e-bb73644f5ff5&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={video_preview_02}
                        height={200}
                    />
                    <VideoDescription title="Lead yourself">
                        Frank Busemann<br />
                        Olympia-Teilnehmer,<br />
                        Leichtathletikexperte der ARD, Autor
                        <div className="grid grid-cols-10 mt-4 text-xs">
                            <div className="col-span-7">
                                Handout zum Video:
                                <br />
                                <Link className="text-white underline " to={Documents[0].url} target="_blank">
                                    {Documents[0].title}
                                </Link>
                            </div>
                            <span className="col-span-3 text-right">
                                <br />
                                pdf | {Documents[0].size}
                            </span>
                        </div>
                    </VideoDescription>
                </div>
                <div className="col-span-1">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=5e679dc9-71ce-4807-b862-a5efaa347b85&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={img_preview}
                        height={200}
                    />
                    <VideoDescription title="Warum Menschen oft an Ihren Stärken scheitern und wie Sie das verhindern">
                        Michael Paschen<br />
                        Geschäftsführer Profil M GmbH<br />
                        <br />
                        <br />
                    </VideoDescription>
                </div>
                <div className="col-span-1">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=a0d01be6-93b9-41f2-8b41-83e737ded8f1&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={video_preview_01}
                        height={200}
                    />
                    <VideoDescription title="Wie Potenzial zur Stärke wird">
                        <br />
                        Michael Paschen<br />
                        Geschäftsführer Profil M GmbH<br />
                        <br />
                        <br />
                    </VideoDescription>
                </div>
            </div>
        </Section>
    )
}

function SmallTiles() {
    return (
        <Section>
            <div className="grid grid-cols-6 gap-5">
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/digitales-coaching">
                    <SmallTile.Title>
                        Coach Hub
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Jede neue Führungskraft hat die Möglichkeit, zur Unterstützung auf dem Weg in die Führungsrolle ein digitales Coaching in Anspruch zu nehmen.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/entwickeln/mentoring">
                    <SmallTile.Title>
                        Mentoring
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Alle Mitarbeitenden der BS SE haben die Möglichkeit sich in Workday als Mentor:in zur Verfügung zu stellen oder nach Mentor:innen zu suchen, um sich durch einen gezielten Erfahrungsaustausch persönlich weiterzuentwickeln.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal">
                    <SmallTile.Title style={{ fontSize: '1.4rem' }}>
                        Entwicklungsportal
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Ihre zentrale Anlaufstelle für
                        Ressourcen, Tools und
                        Informationen. <br /><br /><br />
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/inspiration/selbstreflexion">
                    <SmallTile.Title>
                        Selbstreflexion
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Selbstreflexion ermöglicht es
                        Ihnen, sich von außen zu
                        betrachten, Ihre Stärken
                        anzuerkennen und Ihre
                        Schwächen zu erkennen.<br /><br /><br />
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/inspiration/zielverfolgung">
                    <SmallTile.Title>
                        Ziele
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Führung und Zielbild sind
                        untrennbar miteinander verbunden.
                        Wir unterstützen Sie Ihre
                        Führungsfähigkeiten zu stärken.
                        <br /><br /><br />
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/inspiration/literaturtipps">
                    <SmallTile.Title>
                        Literaturliste
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Eine hilfreiche Sammlung von
                        Dokumenten, Artikeln und vielen
                        weiteren Aspekten der
                        persönlichen Entwicklung.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="relative bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/inspiration/podcasts">
                    <IconPodcast className="absolute w-10 h-10 p-2 bg-white rounded-full fill-tk-blue-light right-6 top-2.5" />
                    <SmallTile.Title>
                        Podcasts
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Hier finden Sie eine Sammlung von verschiedenen Podcasts rund um das Thema Entwicklung und Führung.
                    </SmallTile.Body>
                </SmallTile>
                <div className="relative col-span-4 px-4 py-6 text-white bg-tk-brand">
                    <div className="">
                        <span className="px-2 pt-2 pb-1 leading-none bg-white rounded-xl text-tk-brand font-tk-bold">NEUES LERNANGEBOT</span>
                    </div>
                    <h4 className="mt-4 mb-5 text-xl">
                        tk Steel Advanced Leadership
                    </h4>
                    <p className="text-xs">
                        Sie erhalten in diesem Programm die Chance einen Schritt vom anspruchsvollen Führungsalltag zurückzutreten, um den eigenen Führungsstil mithilfe von Expert:innen und Gleichgesinnten auf Augenhöhe zu reflektieren und weiter zu stärken.
                    </p>
                    <div className="mt-8">
                        <Link to={"https://wd3.myworkday.com/steeleurope/learning/program/9771346dcb4e1001e59945920dd30000?type=2d29754fdb8e100008b50ff6bc94003b"} target='_blank'
                            className="sm:border-white sm:border-solid sm:px-2 sm:pt-3 sm:pb-2 sm:border">
                            <span className="hidden text-sm text-white sm:inline font-tk-medium">
                                Für Teamleitende
                            </span>
                            <IconExternalLink className="inline w-3 ml-4 fill-white" />
                        </Link>
                        <Link to={"https://wd3.myworkday.com/steeleurope/learning/program/1772bb3239161000a55fc87463a50000?type=2d29754fdb8e100008b50ff6bc94003b"} target='_blank'
                            className="ml-4 sm:border-white sm:border-solid sm:px-2 sm:pt-3 sm:pb-2 sm:border">
                            <span className="hidden text-sm text-white sm:inline font-tk-medium">
                                Für Schichtkoordinierende
                            </span>
                            <IconExternalLink className="inline w-3 ml-4 fill-white" />
                        </Link>
                    </div>
                    <a className="absolute top-0 right-4" href="https://wd3.myworkday.com/steeleurope/d/home.htmld" target="_blank" rel="noreferrer" >
                        <img src={img_workday} alt="workday" className="w-24 my-5" />
                    </a>
                </div>
            </div>
        </Section>
    )
}