import { ReactNode } from "react";
import { Link } from "react-router-dom";
import img_workday from "./../images/Entwickeln/workday_icon_white.png";
import IconExternalLink from "./IconExternalLink";
import NavLinkWithContextMenu from "./NavLinkWithContextMenu";

type TileProps = {
    double?: boolean;
    children?: ReactNode;
    image?: string;
    title?: React.ReactNode;
    body?: string;
    linkTo?: string;
    linkToText?: string;
    linkTo2?: string;
    linkTo2Text?: string;
    linkTo2Border?: boolean;
    extraText2?: string;
    extraText2Link?: string;
    className?: string;
    external?: boolean;
    external2?: boolean;
    workday?: boolean;
}

export default function Tile(props: TileProps) {

    const linkTo2Border = (props.linkTo2Border === undefined) ? true : props.linkTo2Border;

    const size = props.double ? 'col-span-12 lg:col-span-6' : 'col-span-12 lg:col-span-4';

    if (props.image) {
        return (
            <div
                className={`min-w-full bg-no-repeat bg-cover bg-[position:center_center] ${size} ${props.className}`}
                style={{ backgroundImage: `url(${props.image})` }}>
            </div>
        );
    }

    return (
        <div className={`flex flex-col text-white p-7 min-h-[20rem] ${size} ${props.className}`}>
            <div className="mb-6 text-2xl font-tk-medium ">{props.title}</div>
            <div className="flex-wrap max-w-sm mb-12 text-sm leading-6">{props.children}</div>
            <div className={`flex-wrap mt-auto ${props.extraText2?.length ? 'mb-12' : 'mb-2'}`}>
                <div className="relative flex items-center justify-between select-none">
                    <TileButton linkTo={props.linkTo} external={props.external}>{props.linkToText}</TileButton>
                    {props.linkTo2 && linkTo2Border && (
                        <>
                            <TileButton linkTo={props.linkTo2} external={props.external2}>{props.linkTo2Text}</TileButton>
                            {props.extraText2 && <Link to={props.extraText2Link ?? "#"} target="_blank" className="absolute right-0 w-48 text-sm text-white underline -bottom-12">{props.extraText2}</Link>}
                        </>
                    )}
                    {props.linkTo2 && !linkTo2Border && (
                        <div className="relative">
                            <TileButtonNoBorder linkTo={props.linkTo2} external={props.external2}>{props.linkTo2Text}</TileButtonNoBorder>
                        </div>
                    )
                    }
                    {props.workday && (
                        <Link to="https://wd3.myworkday.com/steeleurope/d/home.htmld" target="_blank" rel="noreferrer">
                            <img src={img_workday} alt="workday" className="w-20" />
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}

export function TileButton(props: { children?: ReactNode; linkTo?: string; external?: boolean; className?: string; }) {
    return (
        <Link to={props.linkTo ? props.linkTo : "#"} target={props.external ? '_blank' : '_self'}
            className={`px-3 pt-3 pb-2 border border-white border-solid lg:px-4 whitespace-nowrap ${props.className}`}>
            <span className="inline text-sm text-white font-tk-medium">{props.children ?? "Mehr erfahren"}</span>
            {props.external && <IconExternalLink className="inline w-3 ml-2 fill-white" />}
        </Link>
    );
}

function TileButtonNoBorder(props: { children?: ReactNode; linkTo?: string; external?: boolean; }) {
    return (
        <>
            {!props.external &&
                <NavLinkWithContextMenu to={props.linkTo ? props.linkTo : "#"} target='_self'>
                    <span className="hidden text-sm text-white underline sm:inline font-tk-medium">{props.children ?? "Mehr erfahren"}</span>
                </NavLinkWithContextMenu>
            }
            {props.external &&
                <Link to={props.linkTo ? props.linkTo : "#"} target='_blank'>
                    <span className="hidden text-sm text-white underline sm:inline font-tk-medium">{props.children ?? "Mehr erfahren"}</span>
                    <IconExternalLink className="inline ml-4" />
                </Link>
            }
        </>
    );
}