import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroIchMöchte from "../components/HeroIchMöchte";
import Section from "../components/Section";
import Tile from "../components/Tile";
import VideoComponent from "../components/VideoComponent";
import makeTileDataToSearchItem from "../helpers/makeTileDataToSearchItem";
import img_Hero from '../images/Entwickeln/header_bg.svg';
import img_preview from "../images/Mediathek/lernen_und_selbstreflexion_-_warum_menschen_oft_an_ihren_staerken_scheitern_und_wie_sie_das_verhindern.png";
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";
import { TileData } from "../types/TileData";
import img_02 from "./../images//Entwickeln/Entwickeln_02.png";
import links from "./links";

const TilesData: TileData[] = [
    {
        title: "Mich entwickeln",
        link: links["mich entwickeln"],
        external: false,
        description: `
            Ihre persönliche Route zum Erfolg. Profitieren Sie von
            maßgeschneiderten Entwicklungsprogrammen, Coaching
            und Mentoring, um Ihre Führungskompetenzen zu
            erweitern.
            So entfalten Sie als Continuous Learner Ihr volles Potenzial.
        `,
    },
    {
        title: "Mitarbeitende entwickeln",
        link: links["Mitarbeitende entwickeln"],
        external: false,
        description: `
            Aufstiegstalente erkennen und zu neuen Horizonten führen.
            Nutzen Sie moderne Methoden der Potenzialförderung und
            schaffen Sie durch Skill-Trainings und Mentoring eine Kultur
            des kontinuierlichen Lernens und Wachsens in Ihrem Team.
        `,
    },
    {
        title: "Mein Team und meinen Verantwortungsbereich strategisch weiterentwickeln",
        link: links["Meinen Verantwortungsbereich"],
        external: false,
        description: `
            Wir wollen die Zusammenarbeit innerhalb der Teams fördern,
            um die Synergien zwischen den verschiedenen Fachbereichen
            zu nutzen und gemeinsam noch effizienter und innovativer die
            gesetzten Ziele zu erreichen.
        `
    }
];

export const SearchLinks: SearchItem[] = [
    {
        title: "entwickeln",
        link: links["entwickeln"],
        description: "Chancen wahrnehmen, die Komfortzonen verlassen und zu neuen Höhen aufsteigen.",
        type: SearchItemType.InternalLink
    },
    ...TilesData.map(tileData => makeTileDataToSearchItem(tileData)),
    {
        title: "tkSteel Advanced Leadership für Teamleitende",
        link: "https://wd3.myworkday.com/steeleurope/learning/program/9771346dcb4e1001e59945920dd30000?type=2d29754fdb8e100008b50ff6bc94003b",
        type: SearchItemType.ExternalLink,
        description: `
        Sie erhalten in diesem Programm die Chance einen Schritt vom
        anspruchsvollen Führungsalltag zurückzutreten, um den eigenen
        Führungsstil mithilfe von Expertinnen und Gleichgesinnten auf
        Augenhöhe zu reflektieren und weiter zu stärken.
        `,

    },
    {
        title: "tkSteel Advanced Leadership für Schichtkoordinierende",
        link: "https://wd3.myworkday.com/steeleurope/learning/program/9771346dcb4e1001e59945920dd30000?type=2d29754fdb8e100008b50ff6bc94003b",
        type: SearchItemType.ExternalLink,
        description: `
        Sie erhalten in diesem Programm die Chance einen Schritt vom
        anspruchsvollen Führungsalltag zurückzutreten, um den eigenen
        Führungsstil mithilfe von Expertinnen und Gleichgesinnten auf
        Augenhöhe zu reflektieren und weiter zu stärken.
        `,

    }
];

export default function EntwickelnPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <HeroImage />
            <ContainerCenter>
                <Tiles />
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <Section>
            <HeroIchMöchte image={img_Hero} bgClassName="bg-tk-blue-light">
                <HeroIchMöchte.Headline className="text-white">Ich möchte: entwickeln</HeroIchMöchte.Headline>
                <HeroIchMöchte.Header className="text-white">
                    Chancen wahrnehmen, die Komfortzonen verlassen<br />
                    und zu neuen Höhen aufsteigen.
                </HeroIchMöchte.Header>
                <HeroIchMöchte.Body className="text-white">
                    Dieser Weg kann steil und herausfordernd sein, aber der Blick vom Gipfel ist<br />
                    unbezahlbar. Wir helfen Ihnen aufzubrechen, Chancen und Potenziale zu<br />
                    entdecken, zu erweitern und Ihre Visionen zu verwirklichen.
                </HeroIchMöchte.Body>
                <HeroIchMöchte.BlueBody>
                    Betreten Sie neue Pfade und holen Sie sich
                </HeroIchMöchte.BlueBody>
                <HeroIchMöchte.BlueBody>
                    hier das nötige Rüstzeug dafür.
                </HeroIchMöchte.BlueBody>
            </HeroIchMöchte>
        </Section>
    )
}

function Tiles() {
    return (
        <Section>
            <div className="grid grid-cols-12 gap-0">
                <div className="col-span-12 lg:col-span-6">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=5e679dc9-71ce-4807-b862-a5efaa347b85&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={img_preview}
                        height={356}
                        title="Warum Menschen oft an Ihren Stärken scheitern und wie Sie das verhindern"
                        subtitle="Michael Paschen, Geschäftsführer Profil M GmbH"
                    />
                </div>
                <Tile double className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[0].link}
                    external={TilesData[0].external}
                    title={TilesData[0].title}
                >
                    {TilesData[0].description}
                </Tile>
                {/*  */}
                <Tile double className="bg-tk-purple hover:bg-tk-purple-hover"
                    linkTo={TilesData[1].link}
                    external={TilesData[1].external}
                    title={TilesData[1].title}
                >
                    {TilesData[1].description}
                </Tile>
                <Tile double image={img_02} />
                {/*  */}
                <Tile double className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[2].link}
                    external={TilesData[2].external}
                    title={TilesData[2].title}
                >
                    {TilesData[2].description}
                </Tile>
                <Tile double className="bg-tk-blue-dark hover:bg-tk-blue-dark-hover"
                    linkTo="https://wd3.myworkday.com/steeleurope/learning/program/9771346dcb4e1001e59945920dd30000?type=2d29754fdb8e100008b50ff6bc94003b"
                    linkToText="Für Teamleitende"
                    external
                    linkTo2="https://wd3.myworkday.com/steeleurope/learning/program/9771346dcb4e1001e59945920dd30000?type=2d29754fdb8e100008b50ff6bc94003b"
                    linkTo2Text="Für Schichtkoordinierende"
                    workday
                    external2
                    title={<>
                        <div className="mb-4">
                            <span className="px-2 pt-2 pb-1 text-sm leading-none text-white rounded-lg bg-tk-brand">NEUES LERNANGEBOT:</span>
                        </div>
                        <div>
                            „tkSteel Advanced Leadership für Teamleitende und Schichtkoordinierende“
                        </div>
                    </>}>
                    Sie erhalten in diesem Programm die Chance einen Schritt vom
                    anspruchsvollen Führungsalltag zurückzutreten, um den eigenen
                    Führungsstil mithilfe von Expertinnen und Gleichgesinnten auf
                    Augenhöhe zu reflektieren und weiter zu stärken.
                </Tile>
            </div>
        </Section>
    );
}

