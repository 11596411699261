import svg_340x510 from "../../images/Popups/Pop_Up_Mentoring_FKP_340x510.svg";
import svg_750x450 from "../../images/Popups/Pop_Up_Mentoring_FKP_750x390.svg";

interface PopupProps {
    onClose: () => void;
}

export default function PopupMentoring(props: PopupProps): JSX.Element {

    return (
        <div className="relative">
            <div className="absolute z-50 text-2xl leading-none text-white cursor-pointer top-1 right-2 md:top-2 md:right-3" onClick={props.onClose}>&#x2715;</div>
            <PopupDesktopVersion onClose={props.onClose} />
            <PopupMobileVersion onClose={props.onClose} />
        </div>
    );
}

function PopupDesktopVersion(props: PopupProps) {
    return (
        <div className="hidden transition duration-1000 ease-in delay-1000 md:block">
            <img src={svg_750x450} alt="" className="shadow-2xl select-none w-[750px] rounded-2xl" />
            <PopupTitle className="absolute left-0 text-3xl top-8 font-tk-medium text-tk-brand mb-9" />
            <div className="absolute left-0 pl-8 top-36">
                <PopupBody className="text-[#565c61] text-lg font-tk-regular mb-4" />
                <PopupButton onClose={props.onClose} />
            </div>
        </div>
    )
}

function PopupMobileVersion(props: PopupProps) {
    return (
        <div className="block md:hidden">
            <img src={svg_340x510} alt="" className="shadow-2xl select-none w-80 rounded-2xl" />
            <PopupTitle className="absolute left-0 text-2xl top-3 font-tk-medium text-tk-brand mb-9" />
            <div className="absolute left-0 flex flex-col h-full mt-1 top-24 p-9">
                <PopupBody className="text-[#565c61] text-sm leading-relaxed font-tk-regular mb-1" />
                <PopupButton onClose={props.onClose} />
            </div>
        </div>
    )
}

function PopupTitle(props: { className?: string }) {
    return (
        <div className={props.className}>
            <div className="inline-block pl-8 pr-3 mb-1 text-white pt-0.5 md:pt-1 mb:pl-11 bg-tk-brand">Weiterentwicklung und</div>
            <div className="hidden pl-8 pr-3 text-white md:inline-block pt-0.5 md:pt-1 bg-tk-brand">Erfahrungsaustausch durch Mentoring</div>
            <div className="inline-block pl-8 pr-3 mb-1 text-white pt-0.5 md:pt-1 bg-tk-brand md:hidden">Erfahrungsaustausch</div>
            <div className="inline-block pl-8 pr-3 text-white pt-0.5 md:pt-1 bg-tk-brand md:hidden">durch Mentoring</div>
        </div >
    )
}

function PopupBody(props: { className?: string }) {
    return (
        <div className={props.className}>
            <p className="text-tk-text-grey font-tk-bold">
                Melden Sie sich jetzt als Mentor:in, um Ihr Wissen <br className="hidden md:block" />
                weiterzugeben und Mitarbeitende bei ihrer <br className="hidden md:block" />
                Entwicklung zu unterstützen, oder nutzen Sie<br className="hidden md:block" />
                das Mentoring als Mentee für Ihre eigene <br className="hidden md:block" />
                Weiterentwicklung.
            </p>
        </div>
    )
}

function PopupButton(props: PopupProps) {


    return (
        <div className="text-left">
            <a
                href="https://steel-net.thyssenkrupp-steel.com/fileadmin/content/Mitarbeiterthemen/HRgD/Trainingscenter_Kunde/ESS/Mentoring___Feedback/HR23__Training_PD_Mentoring_MA.pdf"
                target="_blank"
                onClick={props.onClose}
                className="text-sm underline cursor-pointer text-tk-brand md:text-base font-tk-bold"
                rel="noreferrer">
                Mehr Informationen hier im Quick-Guide<br />
                im Workday Trainingscenter.
            </a>
        </div>
    )
}


