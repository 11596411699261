// https://fluentsite.z22.web.core.windows.net/quick-start
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import SearchProvider from "../context/SearchContext";
import LoginPage from "../pages/LoginPage";
import Body from "./Body";
import { TeamsFxContext } from "./Context";
import Routes from "./Routes";
import config from "./config";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {

  const { themeString, teamsUserCredential, inTeams, context } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });
  const [validUser, setValidUser] = useState(false);

  const isAuthenticated = useIsAuthenticated();


  const { instance, accounts, inProgress } = useMsal();



  useEffect(() => {

    const run = async () => {

      if (inTeams) {

        teamsUserCredential?.getUserInfo()
          .then((response) => {
            // alert(response.tenantId);
            // alert(config.tenantId);
            if (response.tenantId === config.tenantId) {
              setValidUser(true);
            }
          }).catch((error) => {
            // alert(JSON.stringify(error))
            try {
              if (context?.user?.tenant?.id === config.tenantId) {
                setValidUser(true);
              }
            } catch (error) { }
          });

      } else {
        instance.ssoSilent({
          scopes: config.scopes,
        }).then((response) => {

        }).catch((error) => {
          // console.log(error)
        });
      }
    }

    run();

  }, [context, inTeams, instance, isAuthenticated, teamsUserCredential]);


  if (inProgress !== 'none') return <>{''}</>;

  if (!inTeams && accounts[0] && accounts[0].idTokenClaims?.tid !== config.tenantId) return <>Invalid</>;


  return (

    <Body>
      <TeamsFxContext.Provider value={{ themeString, teamsUserCredential, inTeams }}>
        <FluentProvider theme={teamsLightTheme}>
          {/* {(<div>{"inTeams"} {JSON.stringify(inTeams)}</div>)}
          {(<div>{"validUser"} {JSON.stringify(validUser)}</div>)}
          {(<div>{"isAuthenticated"} {JSON.stringify(isAuthenticated)}</div>)} */}
          {
            inTeams && validUser &&
            <SearchProvider>
              <Routes />
            </SearchProvider>
          }
          {
            !inTeams &&
            <>
              <AuthenticatedTemplate>
                <SearchProvider>
                  <Routes />
                </SearchProvider>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <LoginPage />
              </UnauthenticatedTemplate>
            </>
          }
        </FluentProvider>
      </TeamsFxContext.Provider>
    </Body>
  );
}
