
import DownloadItem from "./DownloadItem";
import Section from "./Section";

export default function Ansprechpartner() {
    return (
        <Section>
            <div className="">
                <h4 className="mb-2 text-lg">Ansprechpartner</h4>
                <DownloadItem item="Information_HR_Organisation_Business_Partner" size="329 KB" url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=02afb184-6de2-4bb9-b97b-0f1dc9799078" />
            </div>
        </Section>
    );
}
