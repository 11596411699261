import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroImage from "../components/HeroImage";
import Section from "../components/Section";
import SmallTile from "../components/SmallTile";
import TKText from "../components/TKText";
import img_Hero from '../images/Ansprechpartner/hero_bg.jpg';

export default function AnsprechpartnerPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage image={img_Hero} alt="Ansprechpartner">
                    <HeroImage.Title>Ansprechpartner</HeroImage.Title>
                </HeroImage>
                <div className="px-4 lg:px-0">
                    <TextSection />
                </div>
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <SmallTiles />
                    </div>
                    <div className="col-span-1">
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Finden Sie den richtigen Ansprechpartner für Ihr Anliegen.
            </TKText.Header>
            <TKText.SubHeader>
                Wir wissen, wie wichtig es ist, direkte Kontakte zu haben, um Ihre Fragen zu
                beantworten, Unterstützung zu bieten oder einfach nur Informationen einzuholen.
            </TKText.SubHeader>
        </Section >
    )
}

function SmallTiles() {
    return (
        <Section>
            <div className="grid grid-cols-6 gap-5">
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/organisation/human-resources-management-se-hrm/business-partner">
                    <SmallTile.Title>
                        Business<br />
                        Partner
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Unsere Schnittstellen für eine enge
                        Zusammenarbeit, um die internen
                        Ressourcen, Fachkenntnisse und
                        Netzwerke zu teilen.<br /><br /><br />
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/organisation/human-resources-management-se-hrm/bp-executives-international-hr-affairs">
                    <SmallTile.Title>
                        Business Partner<br />
                        Executives
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Lassen Sie sich von unseren
                        erstklassigen Ansprechpartnern
                        zielgerichtet auf Ihrem Weg zu
                        nachhaltigem Erfolg begleiten.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/organisation/safety-security-se-ss/health-1/betriebsaerztlicher-dienst">
                    <SmallTile.Title>
                        Betriebsärztlicher<br />
                        Dienst
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Der Dienst um die Gesundheit der Mitarbeitenden am Arbeitsplatz zu erhalten und zu verbessern.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/organisation/human-resources-management-se-hrm/inklusion-werkstaetten/sozialservice">
                    <SmallTile.Title>
                        Sozialservice
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Unser Dienst, der sich um die
                        Bedürfnisse und das
                        Wohlergehen von Menschen
                        kümmert, die Unterstützung und
                        Hilfe benötigen.<br /><br /><br />
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/interessensvertretungen-1-2">
                    <SmallTile.Title>
                        Betriebsrat
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Unser Betriebsrat spielt eine
                        bedeutende Rolle für eine
                        gerechte und transparente
                        Arbeitswelt und trägt zur Stärkung
                        der Arbeitnehmerrechte bei.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/unternehmenssprecherausschuss">
                    <SmallTile.Title>
                        Unternehmens-<br />
                        sprecher-<br />
                        ausschuss
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Der Ausschuss vertritt die Interessen der leitenden Angestellten der thyssenkrupp Steel Europe AG.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/organisation/safety-security-se-ss/arbeitssicherheit">
                    <SmallTile.Title>
                        Arbeitssicherheit
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Sicherheit am Arbeitsplatz hat oberste Priorität. Hier finden Sie die Experten, die uns dabei unterstützen, Risiken zu identifizieren und zu minimieren.<br /><br />
                    </SmallTile.Body>
                </SmallTile>
            </div>
        </Section>
    )
}