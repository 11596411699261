import React from 'react';
import { Link } from 'react-router-dom';
import BasicLayout from '../components/BasicLayout';
import Breadcrumb from '../components/Breadcrumb';
import ContainerCenter from "../components/ContainerCenter";
import FührungsSideLinks from '../components/FührungsSideLinks';
import HeroImage from '../components/HeroImage';
import { RelevanteDokumente } from '../components/RelevanteDokumente';
import Section from "../components/Section";
import TKText from "../components/TKText";
import img_chat from '../images/ZielbildarbeitInBereichen/chat.svg';
import img_coaching from '../images/ZielbildarbeitInBereichen/coaching.svg';
import img_compass from '../images/ZielbildarbeitInBereichen/compass.svg';
import img_flag from '../images/ZielbildarbeitInBereichen/flag.svg';
import img_Hero from '../images/ZielbildarbeitInBereichen/hero_bg.jpg';
import img_mountain from '../images/ZielbildarbeitInBereichen/mountain.svg';
import img_stairs from '../images/ZielbildarbeitInBereichen/stairs.svg';
import { RelevantDocument } from '../types/RelevantDocument';
import links from './links';

export const Documents: RelevantDocument[] = [
    {
        title: "Unsere Unternehmenswerte: Das FLK-Zielbild und unsere Unternehmenswerte",
        size: "76 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=eb4571e9-5914-4947-ae2b-5dda892533d2"
    }
];

export default function ZielbildarbeitInBereichenPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <Breadcrumb items={[
                    <Link to={links["Führungsverständnis stärken"]}>Führungsverständnis stärken</Link>,
                    <Link to={links["Führungs und Leistungskultur"]}>Führungs- und Leistungskultur</Link>,
                    <Link to={links["Zielbildarbeit in Bereichen und der strategische Treiber FLK"]}>Zielbildarbeit</Link>,
                ]} />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <TextSection />
                        <RelevanteDokumente docs={Documents} />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                        <FührungsSideLinks />
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="FLK Dialog">
                <HeroImage.Title>Zielbildarbeit in den Bereichen und</HeroImage.Title>
                <HeroImage.Title>der strategische Treiber FLK</HeroImage.Title>
            </HeroImage>
        </Section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                So arbeiten wir gemeinsam daran,<br />
                das Zielbild zu leben
            </TKText.Header>
            <h4 className='mt-4 mb-4 text-lg text-tk-brand font-tk-medium'>
                Kernelement des Wandels: Bereichsspezifische
                Arbeit in Richtung FLK-Zielbild
            </h4>
            <TKText.Body>
                Kulturwandel ist für uns kein Projekt, das ein Enddatum hat und es ist auch kein reiner Selbstzweck : Kulturentwicklung
                unterstützt dabei, strategische und operative Herausforderungen unter gewandelten Umweltbedingungen effizienter zu
                bewältigen.
            </TKText.Body>
            <TKText.Body>
                Erfolgsentscheidend ist somit, dass Aktivitäten zum Kulturwandel nicht losgelöst, sondern gekoppelt an strategische und
                operative Business-Ziele und Herausforderungen stattfinden. So kann beispielsweise bei der Einführung von Lean
                Management zur Effizienzsteigerung, die Förderung einer positiven Fehl- und Lernkultur unterstützen und Enabler einer
                erfolgreichen Umsetzung werden.
            </TKText.Body>
            <TKText.Body>
                Daher sind wir davon überzeugt, dass dies umso besser gelingen kann, je mehr dabei auf spezifische
                Rahmenbedingungen und Anforderungen eingegangen werden kann. Deshalb haben im Rahmen von Führungs- und
                Leistungskultur alle Unternehmensbereiche den Auftrag, eigenverantwortlich ihre jeweiligen Top 1-3 Handlungsfelder zu
                identifizieren. Dazu entwickeln diese in eigener Steuerung passende Maßnahmen und kommunizieren diese in ihre
                Mitarbeiterschaft und beziehen diese ein.
            </TKText.Body>
            <TKText.Body>
                Um gemeinsam voranzukommen und zu den Fortschritten in den Bereichen auf Stand zu bleiben, werden zentral immer
                wieder Austauschformate für das SET (Steel Executive Team) und SLT (Steel Leadership Team) geboten. Ziel
                ist, voneinander zu lernen, neue Ideen gemeinsam weiterzuentwickeln und zentrale Rahmenbedingungen für erfolgreichen
                Kulturwandel zu identifizieren und zielorientiert zu gestalten.
            </TKText.Body>
            <h4 className='pb-1 mt-4 mb-2 text-lg font-tk-medium text-tk-brand'>Rahmen und Impulse zur bereichsspezifischen Zielbildarbeit - der strategische Treiber FLK</h4>
            <TKText.Body>
                "Culture eats strategy for breakfast" (Management-Experte Peter Drucker, 2006) - auch für unsere Unternehmensstrategie
                wurde die Entwicklung unserer Führungs- und Leistungskultur als einer der zentralen Treiber für unseren
                Unternehmenserfolg identifiziert und priorisiert. Das Treiberteam FLK unter der Leitung unseres CHROs Markus Grolms
                bietet einen Rahmen und zentrale Impulse zur Unterstützung der dezentralen Bereichsaktivitäten. Ziel dieser Angebote ist
                es, Führungskräfte im Wandel zu befähigen, so dass diese effizienter in die Umsetzung zur Bewältigung der vielfältigen
                operativen und strategischen Herausforderungen gehen können.
            </TKText.Body>

            <Folgendes />

            <TKText.Body>
                <p className=' text-tk-brand font-tk-bold'>
                    Für unsere Kulturtransformation gibt es ein Zielbild zur Orientierung, einen Rahmen im strategischen Treiber FLK und bereichsspezifische Initiativen
                    - aber das reicht nicht. Es fehlt der zentralste Hebel: jede:r Führende!
                </p>
            </TKText.Body>
            <TKText.Body>
                Besondere Verantwortung für unsere Führungs- und Leistungskultur liegt bei allen Führungskräften - als Gestalter von
                Teamarbeit und auch als Vorbilder. Unsere Teams schauen auf ihre Führungskräfte. Im Kleinen so wie im Großen. Führungheißt,
                eine Vision zu vermitteln. Den Weg in die Zukunft zu wagen und voranzugehen. Rahmenbedingungen zu schaffen, in
                denen jeder sein Bestes geben kann.
            </TKText.Body>
            <TKText.Body className='font-tk-bold'>
                Wir hoffen, dass unsere Angebote Ihnen dazu gute Bedingungen schaffen. Und wir zählen auf Ihr Engagement für
                unsere gemeinsame Unternehmenskultur als Hebel für ein erfolgreiches thyssenkrupp Steel.
                <br />
                <br />
                Ihre Fragen und Anregungen sind herzlich willkommen, bitte adressieren Sie diese an:<br />
                <a href="mailto:leadershipcommunication@steeleurope.com">leadershipcommunication@steeleurope.com</a>
            </TKText.Body>
        </Section >
    )
}
function Folgendes() {
    return (
        <Section>
            <h4 className='pb-1 mt-4 mb-6 text-lg text-tk-brand font-tk-medium'>Folgendes sind unsere Ansatzpunkte:</h4>
            <Folgende icon={img_compass} title='Orientierung durch das Zielbild und Unternehmenswerte' >
                Es ist wichtig zu wissen, wo wir hinwollen, welche Werte uns einen. Der Blick auf unser <a href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=274c6693-d006-41b0-a735-16bd8cf1451f" target='_blank' rel="noreferrer" >Zielbild</a> zur Führungs- und
                Leistungskultur gibt uns eine Ausrichtung.
            </Folgende>
            <Folgende icon={img_flag} title='Relevanz durch bereichsspezifische Verknüpfung von Business-Zielen mit Kulturwandel'>
                Es ist wichtig, dass wir das Arbeiten an unserer Führungskultur - das WIE - immer mit unseren Zielen - dem
                WAS - in Verbindung bringen. Wenn wir also eine strategische Planung machen, operative Ziele festlegen
                oder ein Projekt aufsetzen, ist es wichtig, darüber zu sprechen, was uns schwerfällt, was uns als Team
                zusammenhält und mit welchen Routinen der Zusammenarbeit wir ans Ziel kommen wollen.
            </Folgende>
            <Folgende icon={img_mountain} title='Vorbilder fördern'>
                Unser Vorstandsteam und unsere Top-Führungskräfte agieren bewusst und sind Vorbild für das Handeln
                nach unseren Werten. In regelmäßigen SET-Offsites und im jährlichen SLT-Offsite wird daher auch Führung
                und Kultur explizit zusammen mit strategischen Herausforderungen zum Thema gemacht.
            </Folgende>
            <Folgende icon={img_chat} title='Impulse und Dialog'>
                Wir holen uns Ideen und Anregungen zu Führung ein und nutzen diese, um unseren Führungsalltag zu
                gestalten. Wir holen uns Ideen und Anregungen zu Führung ein und nutzen diese, um unseren
                Führungsalltag zu gestalten. In unserem Format FLK-Dialog bieten wir Führungskräften Raum für Austausch
                und neuen Input zu aktuellen Führungsherausforderungen. Einen
                Leitfaden als Impuls zur Verbesserung der Führungskräftekommunikation finden Sie <a href="https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/fuehrungskraeftekommunikation" target='_blank' rel="noreferrer">hier.</a>
            </Folgende>
            <Folgende icon={img_stairs} title='Enabling'>
                Und natürlich dürfen unsere Führungskräfte nicht stehenbleiben. Wir bieten für alle Führungskräfte und
                Potenziale <Link to={"https://wd3.myworkday.com/steeleurope/learning"} target='_blank'>Leadership Programme</Link> an.
            </Folgende>
            <Folgende icon={img_coaching} title='Coaching'>
                Um Gelerntes in den Alltag zu bringen, Schwierigkeiten und Herausforderungen vertraulich zu besprechen und
                das eigene Handeln zu reflektieren, kann ein Coaching bereichernd sein. Wir bieten flächendeckend für alle Führungskräfte
                ein einfach zugängliches <Link to={"https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/digitales-coaching"} target='_blank'>digitales Coaching</Link> an.
            </Folgende>
        </Section>
    )
}
function Folgende(props: { title: string, children: React.ReactNode, icon: string }) {
    return (
        <div className='flex flex-row '>
            <div className='basis-32'>
                <img src={props.icon} alt="" className='w-16' />
            </div>
            <div className='basis-full'>
                <h4 className='mb-2 text-lg font-bold text-tk-brand'>{props.title}</h4>
                <TKText.Body>
                    {props.children}
                </TKText.Body>
            </div>
        </div>
    )
}

