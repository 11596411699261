import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import Podcast from "../components/Podcast";
import { RelevanteDokumente } from "../components/RelevanteDokumente";
import Section from "../components/Section";
import TKText from "../components/TKText";
import VideoComponent from "../components/VideoComponent";
import img_preview from "../images/Mediathek/das_geheimnis_der_motivation.png";
import img_Hero from '../images/MeineRolleAlsFuehrungskraft/hero_bg.jpg';
import { RelevantDocument } from "../types/RelevantDocument";

import VideoFührenInkritischenSituationen from "../components/VideoFührenInkritischenSituationen";
import img_preview_02 from '../images/Mediathek/Fuehrungs-Kraefte fuer Fuehrungskraefte.png';
import img_preview_03 from '../images/Mediathek/Transformationsnotwendigkeit_-_Vielfalt_im_DFB.jpeg';
import img_preview_01 from '../images/Mediathek/Ueber Struktur zur Kultur.png';

export const Documents: RelevantDocument[] = [
    {
        title: "Führungs-Kräfte für Führungskräfte",
        size: "74 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=f56b77ef-c942-4f6a-843c-ff81e59d0a42",
        image: img_preview_02
    },
    {
        title: "Cultural Change: Über Struktur zur Kultur",
        size: "2,2 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=b92aafe1-1967-4364-ace9-8733698d0590",
        image: img_preview_01
    },
];


export default function MeineRolleAlsFührungskraftPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="px-4 lg:px-0">
                    <TextSection />
                    <VideoSection />
                </div>
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <PodcastSection />
                        <RelevanteDokumente docs={Documents} />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                        {/* <FührungsSideLinks /> */}
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Mine Rolle als Führungskraft" />
        </section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Herausforderungen angehen und Lösungen finden.
            </TKText.Header>
            <TKText.SubHeader>
                Als Führungskraft tragen Sie eine entscheidende Rolle in Ihrem Team. Sie sind
                nicht nur dafür verantwortlich, Ziele zu setzen und Ergebnisse zu erzielen, sondern
                auch dafür, Ihre Mitarbeitenden zu unterstützen, zu motivieren und ein guter
                Kommunikator zu sein. Um Sie in Ihrer Rolle als Führungskraft zu unterstützen,
                haben wir für Sie folgende Videos, Podcasts und Dokumente zusammengestellt.
            </TKText.SubHeader>

        </Section >
    )
}

function VideoSection() {
    return (
        <Section className="flex items-stretch">
            <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
                <div className="flex-1 col-span-1">
                    <VideoFührenInkritischenSituationen height={200} />
                    <div className="px-4 py-3 text-white h-44 bg-tk-brand">
                        <span className="block pb-1 text-base">Führen in kritischen Situationen</span>
                        <span className="block text-sm">
                            Jan Hagen<br />
                            Prof. of Management Practice, ESMT Berlin
                        </span>
                        <div className="grid grid-cols-10 mt-8 mb-2 text-xs">
                            <a className="col-span-7 text-white underline" href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=a840ad88-6ab5-4cb6-8f47-810d34faf88b" target="_blank" rel="noreferrer">
                                Handout zum Video: Führen in
                                kritischen Situationen</a>
                            <span className="col-span-3 text-right">pdf | 12,1 MB</span>
                        </div>
                    </div>
                </div>
                <div className="flex-1 col-span-1 overflow-hidden">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=9f5d0a60-8e47-4539-a450-f33e34568c5d&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={img_preview}
                        height={200}
                    />
                    <div className="px-4 py-3 text-white h-44 bg-tk-brand">
                        <span className="block pb-1 text-base">Das Geheimnis der Motivation</span>
                        <span className="block text-sm">
                            Michael Paschen<br />
                            Geschäftsführer Profil M GmbH
                        </span>
                    </div>
                </div>
                <div className="flex-1 col-span-1 overflow-hidden">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=3f3674ed-a21c-4112-a5df-b15ac0939aed&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                        preview={img_preview_03}
                        height={200}
                    />
                    <div className="px-4 py-3 text-white h-44 bg-tk-brand">
                        <span className="block pb-1 text-base">Transformationsnotwendigkeit & Vielfalt im DFB – was wir bei Steel daraus lernen können</span>
                        <span className="block text-sm">
                            Katja Kraus <br />
                            Ehemalige deutsche Fußballtorhüterin, Vorstandsmitglied beim HSV, Autorin, Geschäftsführende Gesellschafterin bei Jung von Matt/sports
                        </span>
                    </div>
                </div>
            </div>
        </Section>
    );
}

function PodcastSection() {
    return (
        <Section>

            <h4 className="mb-2 text-lg">Unsere Podcasts zum Thema</h4>
            <div className="grid grid-cols-4 gap-3 lg:grid-cols-12">
                <div className="col-span-4">
                    <Podcast title="Positive Leadership" link="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=4e1523a3-5fe3-423d-9c8e-9abbe2b08479&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create" />
                </div>
                <div className="col-span-4">
                    <Podcast title="Führen in der Krise" link="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=cb7821af-6162-4643-9601-94156f5d3968&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create" />
                </div>
                <div className="col-span-4">
                    <Podcast title="Resilienz" link="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=c726eb1c-2ec2-4d93-a466-379f079a4315&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create" />
                </div>
            </div>


        </Section>
    );
}


