import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import { RelevanteDokumente } from "../components/RelevanteDokumente";
import Section from "../components/Section";
import TKText from "../components/TKText";
import img_Hero from '../images/BewerbungsgespraecheFuehren/hero_bg.png';
import { RelevantDocument } from "../types/RelevantDocument";


export const Documents: RelevantDocument[] = [
    // {
    //     title: "[link?] Informationen zum Recruiting-Prozess",
    //     url: "#"
    // },
    // {
    //     title: "[link?] Do's & Dont's im Recruiting",
    //     url: "#"
    // },
    {
        title: "Rechtliche Fallstricke im Recruiting",
        size: "298 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=a7c019fa-c218-4904-bd21-a1d9ce9de019"
    },
    {
        title: "Tipps und Formulierungshilfen zur Erstellung von erfolgreichen Stellenausschreibungen",
        size: "604 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=8d2cb52a-9af3-4b37-b9aa-43ea51e41bd9"
    },
    {
        title: "Leitfaden für Vorstellungsgespräche",
        size: "160 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=310a427e-e4b5-46f7-98be-e72600e81d82"
    },
    {
        title: "Modularer Fragenkatalog",
        size: "370 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=aa16dc4e-efa7-4c3a-a7ee-fd7cb4631913"
    },
    {
        title: "Interviewbogen",
        size: "408 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/Doc.aspx?sourcedoc={538bbb61-7766-4f1d-99e0-45a5b251a831}&amp;action=embedview"
    },
    {
        title: "Mini-Recruiting-Cases",
        size: "728 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=00e563b5-33c1-4b83-82d2-8226bd28c5c1"
    },
    {
        title: "Videointerviews im Bewerbungsverfahren",
        size: "352 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=3e29262f-2257-4e3b-9a56-c2d33b0e3c3c"
    },
    // {
    //     title: "[link?] Informationen zu Mitarbeiterthemen und Benefits",
    //     url: "#"
    // },
    // {
    //     title: "[link?] Informationen zu tKSE imVorstellungsgespräch",
    //     url: "#"
    // }
];

export default function BewerbungsgesprächeFühren(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="px-4 lg:px-0">
                    <TextSection />
                </div>
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <RelevanteDokumente docs={[Documents[0]]} title="" subtitle="Recruiting-Prozess" />
                        <RelevanteDokumente docs={[Documents[1]]} title="" subtitle="Stellenausschreibung" />
                        <RelevanteDokumente docs={[Documents[2], Documents[3], Documents[4], Documents[5], Documents[6]]} title="" subtitle="Vorstellungsgespräch" />
                        {/* <RelevanteDokumente docs={[Documents[9], Documents[10]]} title="" subtitle="Mitarbeitende gewinnen" /> */}
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Ein Bewerbungsgespräch ist der essenzielle
                Moment im Bewerbungsprozess, um
                potenzielle Arbeitnehmende kennenlernen und
                einschätzen zu können.
            </TKText.Header>
            <TKText.SubHeader>
                Bei der Durchführung von Bewerbungsgesprächen gibt es einige wichtige Elemente, auf die man achten sollte. Hierfür
                stellen wir Ihnen eine Sammlung von hilfreichen Dokumenten zur Verfügung.
            </TKText.SubHeader>
            <h4 className='mt-8 text-lg text-tk-brand font-tk-medium'>Zielprozess Recruiting</h4>
            <TKText.Body>
                Ab dem 1.3.2023
            </TKText.Body>
            {/* <a href={`.${img_Zielprozess_Recruiting}`} target="_blank" rel="noreferrer" >
                <img src={img_Zielprozess_Recruiting} alt="Zielprozess Recruiting" />
            </a> */}
            <a href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=925838bb-11c0-463d-8716-e4b93ddd07a7" target="_blank" rel="noreferrer"  >
                Für mehr Details hier klicken
            </a>
        </Section >
    )
}


