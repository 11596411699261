import { RelevantDocument } from "../types/RelevantDocument";
import DownloadItem from "./DownloadItem";
import Section from "./Section";

export function RelevanteDokumente(props: { docs: RelevantDocument[], title?: string, subtitle?: string }) {
    return (
        <Section>
            <div className="">
                <h4 className="mb-2 text-lg">{props.title ?? "Relevante Dokumente"}</h4>
                {props.subtitle && <p className="mb-2 text-sm text-tk-brand">{props.subtitle}</p>}
                {props.docs.map((doc) => (
                    <DownloadItem key={doc.title} item={doc.title} size={doc.size} url={doc.url} />
                ))}
            </div>
        </Section>
    );
}
