import { MenuItem, MenuList, makeStyles, tokens } from "@fluentui/react-components";
import copy from "copy-to-clipboard";
import { To } from "react-router-dom";
import getMainUrlOfPath from "../helpers/getMainUrlOfPath";
import config from "./config";


interface MenuListContainerProps {
    top: number;
    left: number;
    url: To;
}


const useMenuListContainerStyles = makeStyles({
    container: ({
        backgroundColor: tokens.colorNeutralBackground1,
        position: 'absolute',
        minWidth: '150px',
        minHeight: '48px',
        maxWidth: '300px',
        width: 'max-content',
        boxShadow: `${tokens.shadow16}`,
        paddingTop: '4px',
        paddingBottom: '4px',
        zIndex: 1000
    }),
});



export default function MenuContextContainer(props: MenuListContainerProps): JSX.Element {
    const styles = useMenuListContainerStyles();

    return (
        <div className={styles.container} style={{ top: 20, left: 0 }}>
            <MenuList className="">
                <MenuItem onClick={e => {

                    e.preventDefault();

                    const encodedContext = encodeURI(JSON.stringify({ subEntityId: props.url }));

                    const deepLink = `https://teams.microsoft.com/l/entity/${config.teamsAppId}/fuehrungskompass?label=${encodeURI(props.url.toString().replace("/", "") ?? "")}&context=${encodedContext}`

                    copy(deepLink);

                }}>
                    Teams Link kopieren
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.preventDefault();
                        const link = encodeURI(getMainUrlOfPath(props.url.toString()));
                        copy(link);
                    }}
                >
                    Browser Link kopieren
                </MenuItem>
            </MenuList>
        </div >
    );
}