import { useSearchContext } from "../context/SearchContext";
import SearchPage from "../pages/SearchPage";
import PopupContainer from "./Popup/PopupContainer";
import TKFooter from "./TKFooter";
import TKHeader from "./TKHeader";

export default function BasicLayout(props: { children: React.ReactElement | React.ReactElement[] }): JSX.Element {
    const searchContext = useSearchContext();

    return (
        <>
            <PopupContainer />
            <TKHeader />
            {searchContext.searchText && <SearchPage />}
            {!searchContext.searchText && props.children}
            <TKFooter />
        </>
    );
}