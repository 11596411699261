import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSearchContext } from "../context/SearchContext";
import links from "../pages/links";
import Nav from "./Nav";
import NavLinkWithContextMenu from "./NavLinkWithContextMenu";
import SearchBar from "./SearchBar";
import SubHeader from "./SubHeader";
import TKHeaderSlogan from "./TKHeaderSlogan";
import TKLogo from "./TKLogo";

export default function TKHeader(props: {}): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const searchContext = useSearchContext();

    return (
        <div className="bg-tk-brand">
            <div className="relative flex items-end justify-between mx-auto lg:max-w-4xl h-28 lg:h-32 xl:max-w-5xl">
                <div className="pb-4">
                    <Link to={links.home} onClick={() => searchContext.reset()}>
                        <TKLogo className="w-24 pl-4 lg:pl-0" />
                    </Link>
                </div>
                <div className="flex-grow hidden pb-4 pl-8 sm:block">
                    <TKHeaderSlogan className="h-4" />
                </div>
                <div className="absolute pb-2 text-lg text-white font-tk-medium right-20 lg:right-0 lg:relative">
                    Steel
                </div>
                <div className="lg:hidden">
                    <SearchBar className="mb-10 mr-4" />
                    <BurgerMenu open={open} onClick={() => { setOpen(!open) }} />
                </div>
                <div className="absolute top-0 flex items-center pt-2 text-white right-3 lg:right-0">
                    <SearchBar className="hidden lg:block" />
                    <span className="relative">
                        <NavLinkWithContextMenu to={links["home"]} className="inline-block px-2 text-white">Startseite</NavLinkWithContextMenu>
                        |
                    </span>
                    <span className="relative">
                        <NavLinkWithContextMenu to={links["Ansprechpartner"]} className="inline-block px-2 text-white">Ansprechpartner</NavLinkWithContextMenu>
                        |
                    </span>
                    <span className="relative">
                        <NavLinkWithContextMenu to={links.Mediathek} className="inline-block px-2 text-white last:pl-2 last:pr-0">{t('nav.Mediathek')}</NavLinkWithContextMenu>
                    </span>
                    {/* <LanguageSelector /> */}
                </div>
            </div>
            <SubHeader />
            {open && <div className="block bg-white lg:hidden">
                <Nav />
            </div>}
        </div>
    );
}



function BurgerMenu(props: { open: boolean, onClick: () => void }) {

    return (
        <div className="absolute flex flex-col justify-center bottom-1.5 right-2">
            <div className="relative">
                <nav x-data="{ open: false }">
                    <button className="relative w-10 h-10 text-gray-500 focus:outline-none" onClick={props.onClick}>
                        <span className="sr-only">Open main menu</span>
                        <div className="absolute block w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                            <span aria-hidden="true" className={`absolute block w-5 transition duration-500 ease-in-out transform bg-white h-0.5 ${props.open ? 'rotate-45' : '-translate-y-1.5'}`}></span>
                            <span aria-hidden="true" className={`absolute block w-5 transition duration-500 ease-in-out transform bg-white h-0.5 ${props.open ? 'opacity-0' : ''} `}></span>
                            <span aria-hidden="true" className={`absolute block w-5 transition duration-500 ease-in-out transform bg-white h-0.5 ${props.open ? '-rotate-45' : 'translate-y-1.5'} `}></span>
                        </div>
                    </button>
                </nav>
            </div>
        </div>
    );
}