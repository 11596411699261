import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import Section from "../components/Section";
import SmallTile from "../components/SmallTile";
import TKText from "../components/TKText";
import VideoFeedbackGebenUndNehmen from '../components/VideoFeedbackGebenUndNehmen';
import img_Hero from '../images/FeedbackNehmenUndGeben/hero_bg.png';
import img_04 from "../images/Mediathek/Feedback_OnePage.png";
import img_01 from "../images/Mediathek/Tipps und Tricks fuer Feedbackgeber.png";
import img_02 from "../images/Mediathek/Tipps und Tricks fuer Feedbacknehmer.png";
import img_03 from "../images/Mediathek/Vorbereitung fuer Feedbackgeber.png";

import { RelevantDocument } from "../types/RelevantDocument";
export const Documents: RelevantDocument[] = [
    {
        title: "Tipps und Tricks für Feedbackgeber",
        size: "195 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=52bb15a3-ef84-43de-bb5f-a728d907fd6e",
        image: img_01
    },
    {
        title: "Tipps und Tricks für Feedbacknehmer",
        size: "192 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=5abecde7-569e-48c6-b151-8c777d3efe43",
        image: img_02
    },
    {
        title: "Vorbereitung für Feedbackgeber",
        size: "177 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=23dba94f-0292-4b18-a8d9-dc3bb83edcd3",
        image: img_03
    },
    {
        title: "Feedback OnePager",
        size: "247 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=cd1857e7-a6e8-47cb-afcd-8c94d0ceca4c",
        image: img_04
    },
];



export default function FeedbackNehmenUndGeben(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="px-4 lg:px-0">
                    <TextSection />
                </div>
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <VideoSection />
                        <SmallTiles />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Ein offenes und ehrliches Feedbackgespräch
                ist ein zentraler Bestandteil von
                zwischenmenschlicher Kommunikation.
            </TKText.Header>
            <TKText.SubHeader>
                Feedback kann sowohl positiv als auch negativ sein und ist ein wesentliches
                Instrument zur persönlichen und beruflichen Weiterentwicklung. In diesem Kontext
                ist es wichtig zu verstehen, wie man konstruktives Feedback gibt und wie man es
                auch richtig annimmt, um das Beste daraus zu machen.
            </TKText.SubHeader>
            <TKText.Body>
                Beim Feedback geben ist es wichtig, ehrlich und konstruktiv zu sein. Das Feedback sollte spezifisch sein und sich auf
                beobachtbare Fakten und Verhaltensweisen beziehen. Es ist auch wichtig, das Feedback in einer Weise zu vermitteln, die
                respektvoll und unterstützend ist, um eine positive Wirkung zu erzielen.
            </TKText.Body>
        </Section >
    )
}

function VideoSection() {
    return (
        <Section>
            <VideoFeedbackGebenUndNehmen />
            <div className='p-4 text-white bg-tk-brand'>
                <div className='mb-2 text-base'>
                    Feedback geben und nehmen
                </div>
                <div className='text-sm'>
                    Michael Paschen<br />
                    Geschäftsführer Profil M GmbH
                </div>
            </div>
        </Section>
    );
}


function SmallTiles() {
    return (
        <>
            <Section>
                <div className="grid grid-cols-6 gap-5">
                    <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/inspiration/feedback">
                        <SmallTile.Title>Feedback <br />nehmen und geben</SmallTile.Title>
                        <SmallTile.Body>
                            Seien Sie Teil einer Kultur des kontinuierlichen Lernens und der positiven Veränderung.
                        </SmallTile.Body>
                    </SmallTile>
                    <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo={Documents[3].url}>
                        <SmallTile.Title>{Documents[3].title}</SmallTile.Title>
                        <SmallTile.Body>
                            {" "}
                        </SmallTile.Body>
                    </SmallTile>
                    <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/besprechen/mitarbeitergespraech">
                        <SmallTile.Title>Mitarbeitergespräch</SmallTile.Title>
                        <SmallTile.Body>
                            Erfahren Sie mehr über
                            das Mitarbeitergespräch im Rahmen des integrierten Talent- & Performance-Prozesses LuP@Steel.
                        </SmallTile.Body>
                    </SmallTile>
                </div>
            </Section>
            <Section>
                <div className="grid grid-cols-6 gap-5">
                    <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo={Documents[2].url}>
                        <SmallTile.Title>{Documents[2].title}</SmallTile.Title>
                        <SmallTile.Body>
                            {" "}
                        </SmallTile.Body>
                    </SmallTile>
                    <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo={Documents[0].url}>
                        <SmallTile.Title>{Documents[0].title}</SmallTile.Title>
                        <SmallTile.Body>
                            {" "}
                        </SmallTile.Body>
                    </SmallTile>
                    <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo={Documents[1].url}>
                        <SmallTile.Title>{Documents[1].title}</SmallTile.Title>
                        <SmallTile.Body>
                            {" "}
                        </SmallTile.Body>
                    </SmallTile>
                </div>
            </Section>
        </>
    )
}