import Fuse from "fuse.js";
import React, { createContext, useContext, useState } from "react";
import { Documents as Documents_BewerbungsgesprächeFühren } from "../pages/BewerbungsgesprächeFühren";
import { SearchLinks as SearchLinks_EntwickelnPage } from "../pages/EntwickelnPage";
import { Documents as Documents_FLKDialogPage } from "../pages/FLKDialogPage";
import { Documents as Documents_FeedbackNehmenUndGeben } from "../pages/FeedbackNehmenUndGeben";
import { Documents as Documents_FührungsUndLeistungskulturPage } from "../pages/FührungsUndLeistungskulturPage";
import { SearchLinks as SearchLinks_FührungsverständnisStärkenPage } from "../pages/FührungsverständnisStärkenPage";
import { SearchLinks as SearchLinks_GesprächeFührenPage } from "../pages/GesprächeFührenPage";
import { Podcasts as Podcasts_GutKommunizieren } from "../pages/GutKommunizieren";
import { MediaType, files } from "../pages/MediathekPage";
import { SearchLinks as SearchLinks_MeineAufgabenKennen } from "../pages/MeineAufgabenKennen";
import { Documents as Documents_MeineRolleAlsFührungskraftPage } from "../pages/MeineRolleAlsFührungskraftPage";
import { SearchLinks as SearchLinks_MichEinarbeitenPage } from "../pages/MichEinarbeitenPage";
import { Documents as Documents_MichEntwickelnPage } from "../pages/MichEntwickelnPage";
import { SearchLinks as SearchLinks_WerkzeugeFindenPage } from "../pages/WerkzeugeFindenPage";
import { RelevantDocument } from "../types/RelevantDocument";
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";

const links = [
    SearchLinks_FührungsverständnisStärkenPage,
    SearchLinks_MichEinarbeitenPage,
    SearchLinks_MeineAufgabenKennen,
    SearchLinks_GesprächeFührenPage,
    SearchLinks_EntwickelnPage,
    SearchLinks_WerkzeugeFindenPage
];

const relevantDocuments: RelevantDocument[][] = [
    Documents_BewerbungsgesprächeFühren,
    Documents_FeedbackNehmenUndGeben,
    Documents_FLKDialogPage,
    Documents_FührungsUndLeistungskulturPage,
    Documents_MeineRolleAlsFührungskraftPage,
    Documents_MichEntwickelnPage,
];

const relevantPodcasts: RelevantDocument[][] = [
    Podcasts_GutKommunizieren,
];

const options: Fuse.IFuseOptions<SearchItem> = {
    includeScore: true,
    ignoreLocation: true,
    shouldSort: true,
    keys: ['title', 'description', 'type', 'keywords']
}

const fuse = new Fuse([] as SearchItem[], options);


links.forEach(searchLinks =>
    searchLinks.forEach(link => fuse.add(link))
);

files.forEach(file => {
    const a: SearchItem = {
        title: file.title,
        description: "",
        link: file.url,
        type: file.type === MediaType.PDF ? SearchItemType.PDF : file.type === MediaType.Video ? SearchItemType.Video : SearchItemType.Podcast
    };

    return fuse.add(a);
});


function getSearchFileType(file: RelevantDocument) {
    const ext = file.url.split('.').pop()?.toLowerCase() ?? "";

    if (ext === "pdf") return SearchItemType.PDF;
    if (ext === "mp4") return SearchItemType.Video;

    return SearchItemType.Podcast
}


relevantDocuments.forEach(Doc => Doc.forEach(file => {
    fuse.add({
        title: file.title,
        description: "",
        link: file.url,
        type: getSearchFileType(file)
    })
}));

relevantPodcasts.forEach(Doc => Doc.forEach(file => {
    fuse.add({
        title: file.title,
        description: "",
        link: file.url,
        type: SearchItemType.Podcast
    })
}));



const SearchContext = createContext({
    searchText: "",
    setSearchText: (text: string) => { },
    reset: () => { },
    results: [] as Fuse.FuseResult<SearchItem>[]
});

const SearchProvider = (props: { children: React.ReactElement | React.ReactElement[] }) => {
    const [searchText, setSearchText] = useState("");
    const reset = () => setSearchText("");;

    return (
        <SearchContext.Provider value={{ searchText, setSearchText, reset, results: fuse.search(searchText) }}>
            {props.children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => useContext(SearchContext);

export default SearchProvider;