import { SearchItemType } from "../types/SearchItemType";
import { TileData } from "../types/TileData";

export default function makeTileDataToSearchItem(tileData: TileData) {
    return {
        title: tileData.title,
        description: tileData.description,
        link: tileData.link,
        type: tileData.external ? SearchItemType.ExternalLink : SearchItemType.InternalLink,
        keywords: tileData.keywords
    };
}