import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import Section from "../components/Section";
import SmallTile from "../components/SmallTile";
import TKText from "../components/TKText";
import img_Hero from '../images/MeinenVerantwortungsbereich/header_bg.jpg';
import links from "./links";

export default function MeinenVerantwortungsbereichPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="px-4 lg:px-0">
                    <TextSection />
                </div>
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <SmallTiles />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section className="p-4 lg:p-0">
            <TKText.Header>
                Die Entwicklung eines klaren Verantwortungsbereichs ermöglicht es Mitarbeitenden, ihre Rolle und Erwartungen besser zu verstehen und ihr Potenzial voll auszuschöpfen.
            </TKText.Header>
            <TKText.SubHeader>
                Es geht darum, proaktiv zu handeln, Initiative zu ergreifen und sich über den eigenen aktuellen Aufgabenbereich hinaus zu
                engagieren. Ein gut entwickelter Verantwortungsbereich kann dazu beitragen, die eigenen beruflichen Ziele zu erreichen, das
                Vertrauen der Vorgesetzten zu gewinnen und ein wertvolles Mitglied des Teams zu werden.
            </TKText.SubHeader>
        </Section >
    )
}

function SmallTiles() {
    return (
        <Section>
            <div className="grid grid-cols-6 gap-5">
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steeleurope.sharepoint.com/sites/Strategie-20-30/">
                    <SmallTile.Title>
                        Strategie 20-30
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Die „Strategie 20-30" ist unser
                        Leitbild, welches unser
                        Unternehmen erfolgreich in die
                        nächste Dekade bringen soll.<br /><br /><br />
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/strategiehaus">
                    <SmallTile.Title>
                        Strategiehaus
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Als Unternehmen müssen wir verdeutlichen, warum wir tun, was wir tun, woran wir glauben und wofür wir antreten.
                        Zugleich müssen wir darstellen, wie wir dies erreichen wollen und was wir dafür konkret unternehmen.

                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=eb4571e9-5914-4947-ae2b-5dda892533d2">
                    <SmallTile.Title>
                        Steel Werte
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Sie repräsentieren die
                        gemeinsamen Ziele, Normen und
                        Verhaltensweisen, die die Kultur
                        und den Charakter unseres
                        Unternehmens prägen.
                    </SmallTile.Body>
                </SmallTile>

                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://sp.steeleurope.com/sites/Governance/SitePages/Tiles.aspx ">
                    <SmallTile.Title>
                        Unternehmens-<br />
                        politiken
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Sie umfassen Bereiche wie Ethik,
                        Arbeitssicherheit, Umweltschutz,
                        Datenschutz, Gleichberechtigung
                        und vieles mehr.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo={links["Führungsverständnis stärken"]}>
                    <SmallTile.Title>
                        Führungs-<br />
                        verständnis
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Die Fähigkeit, Visionen zu entwickeln, sowie Kommunikation und Teamarbeit zu fördern.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://wd3.myworkday.com/steeleurope/learning">
                    <SmallTile.Title>
                        Lernangebote
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Ihr Kompass für ständiges Wachstum.
                        Von digitalen eLearnings und Webinaren zu Präsenztrainings. Wir bieten Ihnen ein
                        breites Angebot, um Sie auf Ihrer Führungsreise zu unterstützen.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo={links["Führungs und Leistungskultur"]}>
                    <SmallTile.Title>
                        Führungs- und Leistungskultur
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Ein großer Hebel für unseren zukünftigen Erfolg liegt in der Art und Weise, wie wir zusammen arbeiten. Unser Zielbild zu Führungs- und Leistungskultur gibt dafür eine Orientierung zur gemeinsamen Ausrichtung dieser Entwicklung.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/meine-programme-und-initiativen/diversity-management">
                    <SmallTile.Title>
                        Diversity als<br />
                        Chance
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Die Vielfalt in unserer Gesellschaft,
                        ist mehr als nur ein Schlagwort.
                        Lassen Sie uns gemeinsam die
                        Chancen nutzen, die Diversity uns
                        bietet.<br />
                    </SmallTile.Body>
                </SmallTile>
            </div>
        </Section>
    )
}