import { TeamsUserCredential } from "@microsoft/teamsfx";
import { createContext } from "react";


export const TeamsFxContext = createContext<{
  themeString: string,
  teamsUserCredential?: TeamsUserCredential,
  inTeams: boolean | undefined,
}>({
  themeString: "",
  teamsUserCredential: undefined,
  inTeams: undefined
});
