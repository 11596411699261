import { useEffect, useState } from "react";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import img_Background from '../images/MitarbeiterGewinnen/Background.png';
import img_Background_Buildings from '../images/MitarbeiterGewinnen/Background_Buildings.png';
import img_Background_Road from '../images/MitarbeiterGewinnen/Background_Road.png';
import img_Bridge from '../images/MitarbeiterGewinnen/Bridge.png';
import img_Info_Box from '../images/MitarbeiterGewinnen/Info_Box.png';
import img_Pin from '../images/MitarbeiterGewinnen/Pin_blank.png';
import img_Publikation from '../images/MitarbeiterGewinnen/Publikation.png';
import img_Start from '../images/MitarbeiterGewinnen/Start.png';

export default function MitarbaiterGewinnenPage(props: {}): JSX.Element {

    const [media, setMedia] = useState('');
    useEffect(() => {
        const handleResize = () =>
            (window.matchMedia('(min-width: 1024px)').matches)
                ? setMedia('lg')
                : setMedia('sm');

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return (
        <BasicLayout>
            <ContainerCenter>
                <p className="py-4 pl-6 text-xl lg:py-10 lg:pl-20 lg:text-3xl font-tk-medium">
                    Aufgaben der Führungskraft im Recruitingprozess
                </p>
                <img src={img_Background_Buildings} alt="" className="lg:hidden" />
                <div className="relative flex flex-col items-start px-2 pt-32 bg-repeat-y bg-cover lg:bg-no-repeat lg:px-0 lg:flex-none lg:pt-0" style={{
                    backgroundImage: `url(${media === 'sm' ? img_Background_Road : img_Background})`,
                    height: media === 'sm' ? 'auto' : '1740px',
                    backgroundSize: '100%'
                }}>
                    <img src={img_Info_Box} className="absolute select-none top-[25px] right-5 lg:top-[185px] lg:right-[40px] w-[240px]" alt="" />
                    <img src={img_Start} className="absolute select-none top-[358px] lg:top-[206px] lg:left-[440px] left-[42%] w-[60px]" alt="" />
                    <img src={img_Publikation} className="absolute select-none top-[644px] left-[40%] lg:top-[385px] lg:left-[480px] w-[60px]" alt="" />
                    <Step title="Klärung Stellenbesetzung" className="lg:top-[248px] lg:left-[100px] w-[280px]">
                        <Pin num="01" className="top-[-24px] lg:left-[-24px] right-[-24px]" />
                        <p>
                            Führungskraft organisiert Gespräch mit HR BP zur Initiierung der Ausschreibung.
                        </p>
                        <HilfeLinks>
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=0df6a6e2-c87a-4002-ade0-1d677a13e063" text="Rechtliche Fallstricke im Recruiting" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=ac1fc0c3-4a43-4b09-b781-5eb45c8b2cb6" text="Dos and DONTs im Recruiting" />
                            <HilfeLink href="https://wd3.myworkday.com/steeleurope/learning/course/f972c2d20ea51000facde8fc06270000?type=9882927d138b100019b6a2df1a46018b" text="Link Interviewtrainings" />
                            <HilfeLink href="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/rund-ums-arbeiten/mein-workday/workday-trainingscenter#c56380" text="Link Workday Trainingscenter" />
                        </HilfeLinks>
                    </Step>

                    <Step title="Ergänzung Personalanforderung" className="self-end mt-14 lg:mt-0 lg:top-[268px] lg:left-[570px] w-[310px]">
                        <Pin num="02" className="lg:top-[-42px] top-[-24px] left-[-24px]" />
                        <p>
                            Führungskraft ergänzt Stellentitel,<br />
                            Aufgaben & Profil in Workday.
                        </p>
                        <HilfeLinks>
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=2aff864b-87c9-41bc-84e7-3567840f4222" text="Tipps und Formulierungshilfen zur Erstellung von erfolgreichen Stellenausschreibungen" />
                        </HilfeLinks>
                    </Step>

                    <Step title="Briefing Recruiting" className="lg:top-[450px] lg:left-[440px] w-[260px]">
                        <Pin num="03" className="lg:top-[-42px] lg:left-[-24px] top-[-24px] right-[-24px]" />
                        <p>
                            Recruiter organisiert Briefing: Prozessinformation, Abstimmung Auswahlkriterien etc.
                        </p>
                    </Step>

                    <Step title="Auswahl" className="self-end lg:top-[504px] lg:left-[120px] w-[260px]">
                        <Pin num="04" className="top-[-24px] lg:right-[-24px] lg:left-auto left-[-24px]" />
                        <p>
                            Führungskraft trifft Auswahl, wer zum Interview eingeladen wird.
                        </p>
                    </Step>

                    <Step title="Koordinierung" className="lg:top-[600px] lg:left-[508px] w-[280px]">
                        <Pin num="05" className="top-[-24px] lg:left-[-24px] right-[-24px]" />
                        <p>
                            Recruiter übernimmt Terminierung aller Gespräche, Besucheranmeldung aller externen Kandidaten.
                        </p>
                    </Step>

                    <Step title="Briefing HR Business Partner" className="w-full lg:top-[610px] lg:left-[10px] lg:w-[420px]">
                        <Pin num="06" className="lg:top-[125px] top-[-18px] right-[-6px] lg:right-[-24px] " />
                        <p>
                            HR BP organisiert Briefing zu konkreten CVs, schriftliche Festlegung Gehalt, Eintrittstermin, etc.*
                        </p>
                        <HilfeLinks>
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=072b9ffa-04fe-410d-ab10-979c08be440b" text="Leitfaden für Vorstellungsgespräche" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=5e2dae02-d4a2-4f34-ab7d-40ba58aa1341" text="Modularer Fragenkatalog für Vorstellungsgespräche" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=95F392D4-E1DD-40BD-84AA-D83CBF657ABE" text="Interviewbogen_blanko" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=62433634-95df-4d23-ab1d-463afe756bf2" text="Mini Recruiting Cases" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=d1e60c45-e562-47b5-92c4-3a82a9906300" text="Informationen zu Mitarbeiterthemen im Vorstellungsgespräches" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=57fd4570-2888-4c5f-98f5-e90e3492583c" text="Checkliste für das Vorstellungsgespräch" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=ea4c4f90-883e-4672-a24e-1910f06b7c61" text="Videointerviews im Bewerbungsverfahren" />
                            <HilfeLink href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=E895A852-1378-4F59-8E81-D858289558F6" text="Wir stellen uns vor_Muster" />
                        </HilfeLinks>
                        <p className="mt-2 text-[0.60rem]">
                            * optional bei Schlüsselfunktionen zur gemeinsamen Abstimmung.
                        </p>
                    </Step>

                    <Step title="Anfahrt" className="self-end lg:top-[940px] lg:left-[80px] w-[260px]">
                        <Pin num="07" className="top-[-24px] lg:right-[-24px] lg:left-auto left-[-24px]" />
                        <p>
                            Bei Gesprächen auf dem Werksgelände: Abholung externer Kandidaten durch Fachbereich.
                        </p>
                    </Step>


                    <Step title="Das Interview" className="lg:top-[940px] lg:right-[230px] w-[320px]">
                        <Pin num="08" className="top-[-24px] lg:left-[-24px] right-[-24px]" />
                        <p>
                            Teilnahme HR BP bei Bedarf zur Befähigung, bei Schlüsselfunktionen immer.
                        </p>
                        <HilfeLinks>
                            <HilfeLink href="https://wd3.myworkday.com/steeleurope/learning/course/f972c2d20ea51000facde8fc06270000?type=9882927d138b100019b6a2df1a46018b" text="Interviewtrainings" />
                        </HilfeLinks>
                    </Step>

                    <Step title="Gehalt & Eintrittstermin" className="self-end lg:top-[1110px] lg:right-[230px] w-[320px]">
                        <Pin num="09" className="top-[-24px] left-[-24px]" />
                        <p>
                            Führungskraft ergänzt vereinbartes Gehalt & Eintrittstermin des einzustellenden Kandidaten in Workday.
                        </p>
                    </Step>

                    <Step title="Übermittlung Interview- Entscheidung" className="lg:top-[1140px] lg:left-[80px] w-[300px]">
                        <Pin num="10" className="top-[-24px] right-[-24px]" />
                        <p className="pt-4">
                            Führungskraft übermittelt Entscheidung über Absage oder Zusage in Workday.
                        </p>
                    </Step>

                    <Step title="Bereichsbetriebsrat informieren" className="self-end lg:top-[1280px] lg:left-[110px] w-[330px]">
                        <Pin num="11" className="top-[-32px] lg:right-[-24px] lg:left-auto left-[-24px]" />
                        <p>
                            Führungskraft informiert den Bereichsbetriebsrat über die Besetzungsentscheidung.
                        </p>
                    </Step>

                    <Step title="Rückmeldung an Kandidaten" className="lg:top-[1290px] lg:right-[210px] w-[300px]">
                        <Pin num="12" className="top-[-32px] lg:left-[-24px] right-[-24px]" />
                        <p>
                            Führungskraft informiert Kandidaten telefonisch über Absage (optional) oder Zusage.
                        </p>
                    </Step>

                    <div className="relative right-0 self-center mb-10 select-none lg:mb-0 lg:absolute lg:top-[1300px] lg:right-[35px]">
                        <img src={img_Bridge} className="mx-auto mb-2 w-[150px] lg:mx-0" alt="" />
                        <div className="absolute inline-block w-2 overflow-hidden -left-3 bottom-3">
                            <div className="h-5 origin-top-left transform rotate-45 bg-tk-brand "></div>
                        </div>
                        <a href="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=c8bf85fd-7557-4523-bab9-6676a9cfcd32" target="_blank" rel="noreferrer" className="text-sm leading-tight underline text-tk-brand">
                            Der Übergang zum<br /> Einstellungsprozess „Hire“
                        </a>
                    </div>

                    <div className="w-full mb-20 text-center lg:mb-0 lg:absolute top-[1500px]">
                        <a href="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/rund-ums-arbeiten/mein-workday/workday-trainingscenter#c56380" target="_blank" rel="noreferrer" className="">
                            <span className="block text-tk-brand lg:inline-block px-6 py-3 text-lg leading-none border font-tk-bold border-tk-brand  bg-[#f0f8fd]">
                                Besuchen Sie auch unser Trainingscenter in Workday.
                            </span>
                        </a>
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}


function Step(props: { title: string, children: JSX.Element | JSX.Element[], className?: string }) {
    return (
        <div className={`relative mb-16 lg:mb-0 lg:absolute ${props.className}`}>
            <div className="absolute top-0 w-full px-6 py-2 text-base text-white rounded-full shadow-md bg-tk-brand font-tk-bold">
                <span className="leading-none">{props.title}</span>
            </div>
            <div className="w-full px-6 pt-6 pb-4 mt-4 text-sm leading-tight bg-white shadow-md font-tk-medium rounded-b-2xl">
                {props.children}
            </div>
        </div>
    )
}

function HilfeLinks(props: { children: JSX.Element | JSX.Element[] }) {

    return (
        <div className="mt-3 leading-snug">
            <span className="block mb-1 font-bold">Hilfreiche Dokumente & Links:</span>
            {props.children}
        </div>
    )
}

function HilfeLink(props: { href: string, text: string }) {
    return (
        <a href={props.href} className="block underline" target="_blank" rel="noreferrer">{props.text}</a>
    )
}

function Pin(props: { num: string, className?: string }) {
    return (
        <div className={`absolute w-12 ${props.className}`}>
            <span className="absolute w-full text-base text-center font-tk-bold top-3 pt-0.5 text-[#3078d5]">{props.num}</span>
            <img src={img_Pin} alt={props.num} className="w-12" />
        </div>
    )
}
