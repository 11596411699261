import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroIchMöchte from "../components/HeroIchMöchte";
import Section from "../components/Section";
import Tile from "../components/Tile";
import VideoFeedbackGebenUndNehmen from "../components/VideoFeedbackGebenUndNehmen";
import makeTileDataToSearchItem from "../helpers/makeTileDataToSearchItem";
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";
import { TileData } from "../types/TileData";
import img_02 from "./../images/GespraecheFuehren/Img_02.jpg";
import img_03 from "./../images/GespraecheFuehren/Img_03.jpg";
import img_10 from "./../images/GespraecheFuehren/Img_10.jpg";
import img_Hero from './../images/GespraecheFuehren/hero_bg.svg';
import links from "./links";


const TilesData: TileData[] = [
    {
        title: "Feedback",
        link: links["Feedback nehmen und geben"],
        external: false,
        description: `
            Das GPS für dem gemeinsamen Erfolg -
            geben Sie Ihren Teammitgliedern klare
            Orientierungspunkte vor. Erfahren Sie hier,
            wie Sie motivierende Feedbackgespräche
            führen, Mitarbeiterbewertungen nutzen und
            Ihre Performance stärken.
        `,
    },
    {
        title: "Gut kommunizieren",
        link: links["Gut kommunizieren"],
        external: false,
        description: `
            Klar, präzise und inspirierend - die Kunst der
            effektiven Kommunikation ebnet den Weg.
            Erhalten Sie praktische Tipps für Meetings,
            Teamdialog und virtuelle Zusammenarbeit.
        `,
    },
    {
        title: "Führungsinstrumente",
        link: links["Führungsinstrumente"],
        external: false,
        description: `
            Werkzeuge und Methoden, die Sie optimal
            einsetzen können, um ihre Mitarbeiter effektiv
            zu lenken, zu motivieren und deren Potenzial
            zielführend zum Erreichen unserer
            Unternehmensziele zu fördern.
        `,
    },
    {
        title: "Bewerbungsgespräche führen",
        link: links["Mitarbeiter gewinnen"],
        external: false,
        description: `
            Der richtige Pfad zur Talentgewinnung. Erweitern Sie Ihre
            Interviewfähigkeiten und gewinnen Sie die besten
            Mitarbeiter*innen für Ihr Team. Hier finden Sie
            praxisnahe Tipps und effektive Techniken für erfolgreiche
            Einstellungsentscheidungen.
        `,
    },
    {
        title: "Lernangebote",
        link: "https://wd3.myworkday.com/steeleurope/learning",
        external: true,
        description: `
            Lernen Sie, wie Sie Gespräche strukturieren, schwierige
            Themen ansprechen und Ihre Argumente überzeugend
            präsentieren. Ob Sie Mitarbeitergespräche souverän führen
            oder Kundenbeziehungen stärken möchten - wir bieten Ihnen
            praxisnahe Lernangebote, die Sie zum Kommunikationsexperten machen.
        `
    }
];

export const SearchLinks: SearchItem[] = [
    {
        title: "Gespräche führen",
        link: links["Gespräche führen"],
        description: "Souveräne Kommunikation ist der Weg zum Ziel. Auf diesem Weg gibt es jedoch zahlreiche Herausforderungen.",
        type: SearchItemType.InternalLink
    },
    ...TilesData.map(tileData => makeTileDataToSearchItem(tileData))
];

export default function GesprächeFührenPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <HeroImage />
            <ContainerCenter>
                {/* <VideoPlaceholder /> */}
                <Tiles />
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <HeroIchMöchte image={img_Hero} bgClassName="bg-tk-blue-medium">
                <HeroIchMöchte.Headline className="text-white">Ich möchte: Gespräche führen</HeroIchMöchte.Headline>
                <HeroIchMöchte.Header className="text-white">
                    Souveräne Kommunikation ist der Weg zum Ziel.
                    <br />
                    Auf diesem Weg gibt es jedoch zahlreiche Herausforderungen.
                </HeroIchMöchte.Header>
                <HeroIchMöchte.Body className="text-white">
                    Konfliktreiche Gesprächsfelder, unerwartete Wendungen und manchmal sogar das Gefühl, auf halber<br />
                    Strecke stecken zu bleiben. Jedes Gespräch erfordert daher eine solide Vorbereitung, die richtige<br />
                    Ausrüstung und eine klare Taktik, um an das gewünschte Ziel zu gelangen.
                </HeroIchMöchte.Body>
                <HeroIchMöchte.BlueBody>
                    Ob Kommunikationstraining, aktives Zuhören oder Führungskommunikation - gut gerüstet meistern<br />
                    Sie alle Etappen und gehen mit Ihren Gesprächspartner*innen auf eine erfolgreiche Reise.
                </HeroIchMöchte.BlueBody>
            </HeroIchMöchte>
        </section>
    )
}

function Tiles() {
    return (
        <Section>
            <div className="grid grid-cols-12 gap-0">
                <div className="col-span-12 lg:col-span-8">
                    <VideoFeedbackGebenUndNehmen height={356} title="Feedback geben und nehmen" subtitle="Michael Paschen, Geschäftsführer Profil M GmbH" />
                </div>
                <Tile className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[0].link}
                    external={TilesData[0].external}
                    title={TilesData[0].title}
                >
                    {TilesData[0].description}
                </Tile>
                {/*  */}
                <Tile className="bg-tk-orange hover:bg-tk-orange-hover"
                    linkTo={TilesData[1].link}
                    external={TilesData[1].external}
                    title={TilesData[1].title}
                >
                    {TilesData[1].description}
                </Tile>
                <Tile className="bg-tk-purple hover:bg-tk-purple-hover"
                    linkTo={TilesData[2].link}
                    external={TilesData[2].external}
                    title={TilesData[2].title}
                >
                    {TilesData[2].description}
                </Tile>
                <Tile className="col-span-2" image={img_02} />
                {/*  */}
                <Tile double image={img_03} />
                <Tile double className="bg-tk-blue-light hover:bg-tk-blue-light-hover"
                    linkTo={TilesData[3].link}
                    external={TilesData[3].external}
                    title={TilesData[3].title}
                >
                    {TilesData[3].description}
                </Tile>
                {/*  */}
                <Tile double className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[4].link}
                    external={TilesData[4].external}
                    title={TilesData[4].title}
                >
                    {TilesData[4].description}
                </Tile>
                <Tile double image={img_10} />
                {/*  */}
            </div>
        </Section>
    );
}
