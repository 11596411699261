import { useEffect, useState } from "react";
import PopupMentoring from "./PopupMentoring";



export default function PopupContainer(props: {}): JSX.Element {
    const [showPopup, setShowPopup] = useState(false);
    const lastPopupTimeStorageKey = 'lastPopupTimeMentoring';

    // const shouldShowPopup = (currentDate: Date, eventDate: Date): boolean => {
    //     const lastPopupTime: number | null = parseInt(localStorage.getItem(lastPopupTimeStorageKey) || '', 10);
    //     const daysBeforeEvent: number = 14;
    //     const twoWeeksBeforeEvent: Date = new Date(eventDate.getTime() - daysBeforeEvent * 24 * 60 * 60 * 1000);

    //     if (lastPopupTime && currentDate >= twoWeeksBeforeEvent && currentDate <= eventDate) {
    //         return lastPopupTime < twoWeeksBeforeEvent.getTime() && lastPopupTime > 0;
    //     }

    //     return !lastPopupTime && currentDate >= twoWeeksBeforeEvent && currentDate <= eventDate;
    // };

    const shouldShowPopupMentor = (): boolean => {
        const lastPopupTime: string = localStorage.getItem(lastPopupTimeStorageKey) || '';
        return !lastPopupTime.length;
    }

    useEffect(() => {
        // const currentDate: Date = new Date();
        // const eventDates: Date[] = [
        //     new Date('2023-11-27T11:00:00'),
        //     new Date('2024-01-24T11:00:00'),
        //     new Date('2024-07-05T11:00:00'),
        // ];

        // eventDates.forEach((date) => {

        //     if (currentDate < date && (shouldShowPopup(currentDate, date))) {
        //         setShowPopup(true);
        //     }

        // });

        if (shouldShowPopupMentor()) setShowPopup(true);

    }, []);

    const handleClose = () => {
        const currentTime = Date.now();
        localStorage.setItem(lastPopupTimeStorageKey, currentTime.toString());

        return setShowPopup(false);
    }

    return (
        <>
            {showPopup && <PopupWrapper>
                <PopupMentoring onClose={handleClose} />
            </PopupWrapper>
            }
        </>
    );
}


function PopupWrapper(props: { children: React.ReactNode }) {
    return (
        <div className="fixed z-10 flex items-center justify-center w-screen h-screen before:fixed before:w-screen before:h-screen before:bg-black before:opacity-70">
            <div className="z-20">
                {props.children}
            </div>
        </div>
    )
}