import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroImage from "../components/HeroImage";
import Section from "../components/Section";
import TKText from "../components/TKText";
import img_Hero from '../images/MitarbeiterOnboarden/hero_bg.jpg';
import img_externe from '../images/MitarbeiterOnboarden/img_onboarding-bei-externer-neueinstellung.jpg';
import img_interne from '../images/MitarbeiterOnboarden/img_onboarding-bei-interner-stellenversetzung.jpg';

export default function MitarbeiterOnboardenPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <TextSection />
                <div className="grid grid-cols-12 gap-3 p-4 mb-20 lg:p-0">
                    <ImageWithText image={img_externe} link="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/einstieg/onboarding/onboarding-als-fuehrungskraft-erfolgreich-gestalten/onboarding-bei-externer-neueinstellung">
                        Onboarding bei externer Neueinstellung
                    </ImageWithText>
                    <ImageWithText image={img_interne} link="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/einstieg/onboarding/onboarding-als-fuehrungskraft-erfolgreich-gestalten/onboarding-bei-interner-stellenversetzung">
                        Onboarding bei internem Stellenwechsel
                    </ImageWithText>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}
function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="Mitarbeitende Onboarden">
                <HeroImage.Title>Mitarbeitende Onboarden</HeroImage.Title>
            </HeroImage>
        </Section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Onboarding erfolgreich gestalten
            </TKText.Header>
            <TKText.SubHeader>
                Der erste Eindruck zählt!<br />
                Das gilt nicht nur für Bewerbungsgespräche, sondern auch für die ersten Tage und Wochen eines neuen Mitarbeitenden in Ihrem Team. Ein gelungenes Onboarding ist entscheidend für die Integration, Motivation und Bindung der neuen Kolleg:innen.
            </TKText.SubHeader>
            <TKText.Body>
                Als Führungskraft spielen Sie dabei eine wichtige Rolle: Sie sind nicht nur der/die erste Ansprechpartner:in bei Fragen und Problemen, sondern auch Wegweiser:in für die Erwartungen, Werte und Ziele Ihres Teams und Ihrer Organisation.
                Auf diesen Seiten finden Sie alle wichtigen Informationen und Dokumente für ein erfolgreiches Onboarding. Darüber hinaus erhalten Sie wertvolle Tipps für das virtuelle Onboarding im hybriden Kontext.
            </TKText.Body>
            <TKText.Body className="font-bold">
                Lassen Sie uns gemeinsam starten und Ihre neuen Mitarbeitenden zu einem erfolgreichen Teil Ihres Teams machen!
            </TKText.Body>
        </Section >
    )
}

function ImageWithText(props: { image: string, link: string, children: string }) {
    return (
        <a className="relative col-span-12 lg:col-span-6" href={props.link} target="_blank" rel="noreferrer">
            <img src={props.image} alt={props.children} className="w-full" />
            <div className="px-4 py-2 leading-none text-white bg-tk-brand font-tk-bold">
                {props.children}
            </div>
        </a>
    )
}