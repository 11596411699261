import { useState } from "react";
import img_play from "../images/Playbutton.png";
import { VideoDescription } from "./VideoDescription";

export default function VideoComponent(props: { url: string, preview: string, height?: number, title?: string, subtitle?: React.ReactNode, top?: boolean }): JSX.Element {

    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div className="relative" onClick={() => setIsPlaying(true)}>
            <a href={props.url} target="_blank" rel="noreferrer"
                className="flex flex-col items-center justify-center w-full bg-cover"
                style={{ backgroundImage: `url(${props.preview})`, height: props.height ?? '430px' }}>
                <img src={img_play} alt="play" />
            </a>
            {/* <ReactPlayer
                url={props.url}
                controls
                light={props.preview}
                onClickPreview={() => setIsPlaying(true)}
                playIcon={<img src={img_play} alt="play" />}
                width="100%"
                height={`${props.height ?? 430}px`} /> */}
            {
                props.title && !isPlaying && <VideoDescription title={props.title} className={`absolute w-4/5 ${props.top ? 'top-8' : 'bottom-8'}`}>
                    {props.subtitle}
                </VideoDescription>
            }
        </div>
    );
}