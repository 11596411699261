import { useState } from 'react';
import { Link } from 'react-router-dom';
import BasicLayout from '../components/BasicLayout';
import Breadcrumb from '../components/Breadcrumb';
import ContainerCenter from "../components/ContainerCenter";
import FührungsSideLinks from '../components/FührungsSideLinks';
import HeroImage from '../components/HeroImage';
import { RelevanteDokumente } from '../components/RelevanteDokumente';
import Section from "../components/Section";
import TKText from "../components/TKText";
import img_FLK_01 from '../images/FuehrungsUndLeistungskultur/FLK_01.jpg';
import img_FLK_02 from '../images/FuehrungsUndLeistungskultur/FLK_02.jpg';
import img_FLK_03 from '../images/FuehrungsUndLeistungskultur/FLK_03.jpg';
import img_FLK_04 from '../images/FuehrungsUndLeistungskultur/FLK_04.jpg';
import img_Hero from '../images/FuehrungsUndLeistungskultur/hero_bg.jpg';
import img_unser_zielbild from '../images/FuehrungsUndLeistungskultur/unser_zielbild.png';
import { RelevantDocument } from '../types/RelevantDocument';
import links from './links';

export const Documents: RelevantDocument[] = [
    {
        title: "Zielbild Führungs- und Leistungskultur",
        size: "24 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=274c6693-d006-41b0-a735-16bd8cf1451f"
    },
];


export default function FührungsUndLeistungskulturPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <Breadcrumb items={[
                    <Link to={links["Führungsverständnis stärken"]}>Führungsverständnis stärken</Link>,
                    <Link to={links["Führungs und Leistungskultur"]}>Führungs- und Leistungskultur</Link>,
                ]} />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <TextSection />
                        <ZielBild />
                        <Articles />
                        <BlueSection />
                        <RelevanteDokumente docs={Documents} />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                        <FührungsSideLinks />
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="Führungs- & Leistungskultur">
                <HeroImage.Title>Führungs- & Leistungskultur</HeroImage.Title>
            </HeroImage>
        </Section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Ein starkes Zielbild ist der Schlüssel, um unsere
                Vorhaben in die Realität umzusetzen.
            </TKText.Header>
            <TKText.SubHeader>
                Komplex, volatil und vor allem hochdynamisch: das sind die Schlagworte für
                unser Marktumfeld und die Arbeitswelt, in der wir uns bewegen. Ein großer Hebel für
                unseren zukünftigen Erfolg liegt dabei in der Art und Weise, wie wir zur Erreichung unserer Unternehmensziele zusammenarbeiten und auch führen.
                Unser Zielbild zu Führungs- und Leistungskultur gibt
                Orientierung zur gemeinsamen Ausrichtung dieser Entwicklung.
            </TKText.SubHeader>
            <h4 className='mt-8 mb-4 text-lg text-tk-brand font-tk-medium'>
                Kultur und Unternehmenserfolg
            </h4>
            <TKText.Body>
                Führungs- und Leistungskultur bedeutet für uns, aus der Art der Zusammenarbeit ein Mehr an Qualität und
                auch Effizienz zu gewinnen, weil nicht allein Hierarchie und Fachlichkeit entscheiden, sondern die Teamleistung und das
                Ergebnis verschiedener Perspektiven. Somit ist unsere Führungs- und Leistungskultur der Hebel, WIE wir erfolgreich
                unsere Unternehmensziele und die Transformation gemeinsam umsetzen können. Und ein Wandel kann uns nur
                gemeinsam gelingen, also wenn jede und jeder Einzelne die Veränderung mitgeht. Unseren Führungskräften kommt dabei
                als Vorbilder eine besondere Bedeutung zu.
            </TKText.Body>
            <h4 className='mt-8 mb-4 text-lg text-tk-brand font-tk-medium'>Unser Zielbild Führungs- und Leistungskultur</h4>
            <TKText.Body>
                Das Zielbild bietet uns einen Rahmen zur gezielten Weiterentwicklung unserer Führung und Zusammenarbeit. Es beruht auf
                Überlegungen im Vorstandsteam und wurde im Juni 2022 in einem Workshop mit den Kolleg:innen des Steel Executive-Teams (SET)
                hinterfragt, ergänzt und schließlich verabschiedet. Dazu haben die Führungskräfte des SETs vorab Feedback
                von Mitarbeitenden und Führungskräften in ihren Bereichen eingeholt und in den Workshop stell-vertretend eingebracht.
            </TKText.Body>

        </Section >
    )
}

function BlueSection() {
    return (
        <Section className="p-6 text-center text-white bg-tk-brand">
            <p className='text-sm leading-relaxed'>
                Unser Engagement in all den vier Erfolgsfaktoren des Zielbilds wird Schritt für Schritt dazu führen, die
                Zusammenarbeit in unserem Unternehmen auf neue Beine zu stellen: zielführender, performanceorientierter und
                wertschätzender. Und wie wir dies in Umsetzung bringen, wird hier erläutert.
            </p>
            <div className='mt-8 mb-2'>
                <Link
                    to={links["Zielbildarbeit in Bereichen und der strategische Treiber FLK"]}
                    className="px-4 py-3 text-sm leading-10 bg-white border border-white border-solid lg:leading-none text-tk-brand ">
                    Zielbildarbeit in Bereichen und der strategische Treiber FLK
                </Link>
            </div>
        </Section>
    )
}


function Articles() {
    return (
        <Section>
            <div className='grid grid-cols-1 gap-3 lg:grid-cols-2'>
                <Article img={img_FLK_01} number='1.' title={() => (<>{"Erfolgsfaktor"} <br /> {"Befähigung"}</>)}>
                    Wir können das besser, wenn wir unsere Teams
                    befähigen und wertschätzen, statt im Mikro-
                    Management unterzugehen. Denn wir sind davon
                    überzeugt, dass niemand von uns allein besser ist als
                    wir alle zusammen. Daher ist es die Aufgabe unserer
                    Führungskräfte, das Potenzial, das in jedem und jeder
                    Einzelnen steckt, zu entfalten. Ziel ist es,
                    Mitarbeitende dort einzusetzen, wo diese ihr Potenzial
                    abrufen können und sie systematisch und
                    zukunftsgerichtet weiterzuentwickeln - dies ist auch
                    ein zentraler Faktor, um Motivation zu stärken.
                </Article>
                <Article img={img_FLK_02} number='2.' title={() => (<>{"Erfolgsfaktor"} <br /> {"Eigenverantwortung"}</>)}>
                    Wir können das besser, wenn wir durch klare Ziele
                    Eigenverantwortung fördern, anstatt Befehle zu erteilen. Wir
                    freuen uns darüber, selbst Verantwortung für unsere
                    Themen zu übernehmen. Eigenverantwortung bedeutet,
                    sowohl Freiräume zu gestalten als auch Anforderungen
                    mutig anzunehmen.<br /><br />
                    Die Klarheit bei Prioritäten, Zuständigkeiten und Rollen und
                    die Unterstützung unserer Führungskräfte geben uns die
                    notwendige Handlungssicherheit. Wir fühlen uns für das
                    Ergebnis unserer Arbeit verantwortlich und versuchen,
                    selbst und eigenverantwortlich Lösungen zu finden. Das
                    macht uns schneller und vermeidet Doppelarbeit.
                </Article>
                <Article img={img_FLK_03} number='3.' title={() => (<>{"Erfolgsfaktor"} <br /> {"Kontinuierliche Verbesserung"}</>)}>
                    Wir können das besser, wenn wir Standards einhalten
                    und weiterentwickeln, statt immer wieder Feuer zu
                    löschen. Das heißt, wir wechseln unseren Modus: von
                    defensiv auf proaktiv. Wir jagen nicht Störungen auf
                    der Suche nach Schuldigen hinterher. Stattdessen
                    definieren wir robuste Standards, setzen diese
                    konsequent um und hinterfragen diese, um sie
                    ständig weiterzuentwickeln. Fehler sind dabei
                    hilfreiche Hinweise, aus denen wir lernen.
                    Mitarbeitende und Führungskräfte bringen Projekte
                    und Programme umsetzungsstark, effizient und
                    erfolgreich zu Ende und verankern die Ergebnisse
                    nachhaltig.
                </Article>
                <Article img={img_FLK_04} number='4.' title={() => (<>{"Erfolgsfaktor"} <br /> {"Unternehmergeist"}</>)}>
                    Wir können das besser, wenn wir leistungsorientiert und
                    unternehmerisch mit Blick auf das große Ganze handeln,
                    statt in Silos zu denken. Wir sind überzeugt, dass dadurch
                    bessere Entscheidungen getroffen und Zielkonflikte
                    schneller gelöst werden. Und wir sind ehrgeizig, denn der
                    Erfolg spornt uns an - unser Leistungsanspruch an uns ist
                    auf Wachstum ausgerichtet und somit ambitioniert. Daher
                    ruhen wir uns nicht in unserer Komfortzone aus, sondern
                    nehmen Herausforderungen an. Mitarbeitende und
                    Führungskräfte haben den Mut, neue unkonventionelle
                    Ideen und Ansätze einfach mal auszuprobieren. Denn ohne
                    Mut zur Innovation können wir den neuen
                    Herausforderungen nicht gerecht werden und würden
                    unsere Transformation nicht schaffen.
                    <br />

                </Article>
            </div>
        </Section>
    );
}

function Article(props: { img: string, number: string, title: () => JSX.Element, children: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    return (
        <div className="col-span-1">
            <div className='border border-solid cursor-pointer border-tk-brand group' onClick={() => setOpen(!open)}>
                <img src={props.img} alt="" className="w-full " />
                <div className={`relative group-hover:bg-tk-brand ${open ? 'bg-tk-brand' : ''}`}>
                    <span className='absolute left-0 right-0 justify-center w-8 m-auto text-base leading-8 text-center text-white rounded-full group-hover:text-tk-brand -top-4 font-tk-bold bg-tk-brand group-hover:bg-white'>{props.number}</span>
                    <h4 className={`pt-6 pb-4 text-xl text-center text-tk-brand group-hover:text-white font-tk-medium ${open ? 'text-white' : ''}`}>{props.title()}</h4>
                </div>
            </div>
            {
                open && <TKText.Body className={`p-4 text-center text-white bg-tk-brand ${open ? 'block' : 'hidden'}`}>
                    {props.children}
                </TKText.Body>
            }
        </div>
    )
}

function ZielBild() {
    return (
        <div className='mt-4 mb-10'>
            <a href={img_unser_zielbild} target='_blank' rel="noreferrer">
                <img src={img_unser_zielbild} alt="Unser Zielbild" className="w-full border border-solid border-tk-brand" />
            </a>
        </div>
    );
}
