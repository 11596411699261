import { useState } from 'react';
import BasicLayout from '../components/BasicLayout';
import ContainerCenter from "../components/ContainerCenter";
import HeroImage from '../components/HeroImage';
import { RelevanteDokumente } from '../components/RelevanteDokumente';
import Section from "../components/Section";
import TKText from "../components/TKText";
import img_Boss_Woman from '../images/LGBTQPlus/BOSS_Women.jpg';
import img_icon_mail from '../images/LGBTQPlus/Icon_mail.png';
import img_Infographic from '../images/LGBTQPlus/Infographic_LGTBQplus.png';
import img_Diversity from '../images/LGBTQPlus/Logo_thyssen_Diversity.png';
import img_pride from '../images/LGBTQPlus/Pride_at_steel.png';
import img_WOW from '../images/LGBTQPlus/WOW.jpg';
import img_Hero from '../images/LGBTQPlus/hero_bg.jpg';
import img_video_preview from '../images/LGBTQPlus/video_preview.png';

import VideoComponent from '../components/VideoComponent';
import { RelevantDocument } from '../types/RelevantDocument';

export const Documents: RelevantDocument[] = [
    {
        title: "Informationen und Handlungshilfen für Führungskräfte und Mitarbeitende",
        size: "604 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=70b7e7bc-3880-4bf9-8723-9b840a9f8b7e"
    },
    {
        title: "Leitfaden_gendergerechte_Sprache",
        size: "3,2 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=afa66c94-b96b-422d-b726-6fa8e78cdfc2"
    },
    {
        title: "Eine kurze Einführung in Gender Transition",
        size: "314 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=6d141b77-244e-4494-847a-3dcb111525b5"
    },
];


export default function LGBTQPlusPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <VielfaltLebenSection />
                <FrauenInFeuhrungspositionenSection />
                <WowSteelSection />
                <PrideSteelSection />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <RelevanteDokumente docs={[Documents[0]]} subtitle='lgbt+ bei Steel' />
                        <RelevanteDokumente docs={[Documents[1]]} title="" subtitle='Gendergerechte Sprache' />
                        <RelevanteDokumente docs={[Documents[2]]} title="" subtitle='Gender Transition' />
                        <a
                            href="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/meine-programme-und-initiativen/diversity-management"
                            target='_blank'
                            className='inline-block px-4 mb-20 text-lg border pt-1.5 pb-0.5 border-tk-brand font-tk-medium'
                            rel="noreferrer">
                            Zur diversity@steel Seite
                        </a>
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">

                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="Führungs- & Leistungskultur">
                <HeroImage.Title>Vielfalt als</HeroImage.Title>
                <HeroImage.Title>Chance</HeroImage.Title>
            </HeroImage>
        </Section>
    )
}

function VielfaltLebenSection() {
    const [imgZoom, setImgZoom] = useState(false);

    return (
        <Section>
            <div className="px-4 lg:px-0">
                <TKText.Header>
                    Vielfalt leben
                </TKText.Header>
                <TKText.SubHeader>
                    Vielfalt und Inklusion sind keine Option, sondern eine unternehmerische Notwendigkeit. Durch die Integration unterschiedlicher Perspektiven, Hintergründe und Fähigkeiten fördern wir Innovationen und stärken die Wettbewerbsfähigkeit und Arbeitgeberattraktivität.
                </TKText.SubHeader>
            </div>
            <div className="grid grid-cols-9 p-4 mb-10 lg:gap-6 lg:p-0">
                <div className="col-span-9 mb-10 lg:col-span-6 lg:mb-0">
                    <TKText.Body>
                        Als Führungskraft liegt es in Ihrer Verantwortung, eine inklusive Arbeitsumgebung zu schaffen, in der Mitarbeitende ihre Potenziale entfalten können und sich wertgeschätzt und respektiert fühlen.
                    </TKText.Body>
                    <h4 className='mt-8 mb-4 text-lg text-tk-brand font-tk-medium'>
                        „Diversity is not about how we differ. Diversity is about embracing one another’s uniqueness.”
                    </h4>
                    <p className='-mt-4'>Ola Joseph</p>
                    <h4 className='mt-8 mb-4 text-lg text-tk-text-grey font-tk-medium'>Die Diversity Dimensionen im Modell der Persönlichkeit</h4>
                    <img src={img_Infographic} alt='' className={`mx-auto transition-all duration-500 ease-in cursor-pointer lg:ml-0 ${imgZoom ? 'w-full pl-0' : 'lg:pl-14 w-1/2'}`} onClick={() => setImgZoom(!imgZoom)} />
                    <div className={`mx-auto mt-10 text-sm text-center transition-all duration-500 ease-in text-tk-text-grey lg:ml-0 ${imgZoom ? 'w-full' : 'lg:w-3/5'}`} onClick={() => setImgZoom(!imgZoom)}>Zum Vergrößern klicken</div>
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <img src={img_Diversity} alt='' className='mx-auto' />
                    <a
                        href='https://wd3.myworkday.com/steeleurope/learning/course/f5abfdbbc928100146ffb350a95b0000?record=f5abfdbbc92810014b19411ad7980001&type=9882927d138b100019b928e75843018d'
                        target='_blank'
                        className='bg-[#edf0f2] p-4 mt-10 block'
                        rel="noreferrer">
                        <h4 className='mb-2 text-base text-tk-brand font-tk-bold'>
                            Führungskräfte Training
                        </h4>
                        <p className='mb-2 text-tk-text-grey font-tk-bold'>
                            „Unconscious Bias – Bewusster Umgang mit unbewussten Denkmustern“
                        </p>
                        <TKText.Body>
                            Unbewusste Vorurteile hat jeder. In dem Training erfahren lernen Sie die Mechanismen unbewusster Denkmuster kennen und wie Sie Schubladendenken überwinden können.
                        </TKText.Body>
                        <p className='block text-sm text-tk-brand font-tk-bold' rel="noreferrer">
                            Weitere Informationen und Anmeldungen zum Training via Workday →
                        </p>
                    </a>
                    <h4 className='mt-10 mb-2 text-base text-tk-brand font-tk-bold'>
                        Simple Show Video: „Sprachgebrauch“
                    </h4>
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=d948aee5-beb2-4a6e-9d9b-7c6be491256b&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
                        preview={img_video_preview}
                        height={180}
                    />
                    {/* <img src={img_video_preview} alt='' /> */}
                </div>
            </div>
        </Section >
    )
}

function FrauenInFeuhrungspositionenSection() {
    return (
        <Section>
            <div className="px-4 lg:px-0">
                <TKText.Header>
                    Frauen in Führungspositionen
                </TKText.Header>
                <TKText.SubHeader>
                    Wenn wir nach den Besten suchen, dann halten sie Ausschau nach den spezifischen Fähigkeiten von Männern UND Frauen und wir bieten karriere-motivierten Frauen wie Männern gleiche Entwicklungsmöglichkeiten. Wir stärken Frauen, ihren gleichberechtigten Platz in der Karrierewelt zu suchen und zu finden, und arbeiten kontinuierlich an unseren Rahmenbedingungen, dass sie diesen auch einnehmen können.
                </TKText.SubHeader>
            </div>
            <div className="grid grid-cols-2 gap-16 p-4 lg:p-0">
                <div className="col-span-2 lg:col-span-1">
                    <TKText.Body>
                        Bis zum Geschäftsjahr 24/25 wollen wir den Anteil von Frauen in Führungspositionen auf 9,6% (A-L3) im ganzen Segment erhöhen. Zudem ist die SE-AG dem zweiten Führungspositionen-Gesetzes verpflichtet und hat verbindliche Zielzahlen von Frauen in Führungspositionen für den Aufsichtsrat, Vorstand, erste und zweite Ebene unterhalb des Vorstands bis zum 30.06.2027 definiert.
                    </TKText.Body>
                    <ul className='mb-6 text-tk-brand'>
                        <li><span className='font-bold'>→</span> Aufsichtsrat: 33 %</li>
                        <li><span className='font-bold'>→</span> Vorstand: 20 %</li>
                        <li><span className='font-bold'>→</span> VS-1: 15 %</li>
                        <li><span className='font-bold'>→</span> VS-2: 15 %</li>
                    </ul>
                    <TKText.Body>
                        Daher ergreifen wir Maßnahmen zur Steigerung des Frauenanteils im Unternehmen und insbesondere in Führungspositionen. Wir fördern Rollenvielfalt und geschlechterunabhängige Angebote zur Vereinbarung von Beruf und Privatem. Durch angepasste HR-Instrumente und Maßnahmen vom Recruiting über Entwicklung und Beförderung bis zur Gestaltung von Beschäftigungsbedingungen und flexible Arbeitsmodelle.
                    </TKText.Body>
                </div>
                <div className="col-span-2 lg:col-span-1">
                    <img src={img_Boss_Woman} alt='' />
                </div>
            </div>
        </Section >
    )
}

function WowSteelSection() {
    return (
        <Section>
            <div className="px-4 lg:px-0">
                <TKText.Header>
                    wow!@steel – das Frauennetzwerk
                </TKText.Header>
                <TKText.SubHeader>
                    Starke Netzwerke sind ein Erfolgsfaktor – nicht nur für die Belegschaft, sondern auch für Unternehmen!<br />
                    Deshalb gibt es bereits seit 2013 das konzernweite Frauennetzwerk wow! Web of women. Wow! vertritt die Interessen insbesondere der Frauen bei thyssenkrupp.
                </TKText.SubHeader>
            </div>
            <div className="grid grid-cols-9 gap-6 p-4 mb-20 lg:p-0">
                <div className="col-span-12 lg:col-span-6">
                    <TKText.Body>
                        Wow!@steel bringt weibliche Fach- und Führungskräfte bei Steel zusammen und bietet regelmäßigen und offenen Austausch. Dieser ermöglicht es uns, weibliche Talente sichtbar zu machen, voneinander zu lernen, einander zu helfen, uns gegenseitig Feedback zu geben und uns zu motivieren. Gemeinsam arbeiten wir an spannenden Projekten und Formaten und stärken die Vielfalt bei Steel und die Chancen von Frauen auf der Hütte.<br />
                        Bist du eine weibliche Fach- oder Führungskraft und möchtest dich aktiv bei wow!@steel engagieren oder mehr über die Arbeit von wow! erfahren, dann melde dich gerne direkt bei den Koordinatorinnen:
                    </TKText.Body>
                    <a
                        href="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/meine-programme-und-initiativen/diversity-management/mitarbeitenden-netzwerke-bei-steel"
                        target='_blank'
                        className='inline-block px-4 text-lg border pt-1.5 pb-0.5 border-tk-brand font-tk-medium'
                        rel="noreferrer">
                        Zum Netzwerk wow@steel
                    </a>
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <img src={img_WOW} alt='' />
                </div>
            </div>
        </Section >
    )
}

function PrideSteelSection() {
    return (
        <Section>
            <div className="px-4 lg:px-0">
                <TKText.Header>
                    pride@steel – das Netzwerk die lgbt+ Community
                </TKText.Header>

            </div>
            <div className="grid grid-cols-9 gap-6 p-4 mb-20 lg:p-0">
                <div className="col-span-12 lg:col-span-6">
                    <TKText.SubHeader>
                        Wir schätzen die Vielfalt unserer Belegschaft und betrachten sie als
                        entscheidend für den Unternehmenserfolg. Entsprechend unserer
                        Unternehmenskultur fördern wir bei der thyssenkrupp Steel Europe AG
                        ein offenes und respektvolles Arbeitsumfeld, in dem sich alle
                        Beschäftigten unabhängig ihrer sexuellen Orientierung und/oder Identität, willkommen fühlen.
                    </TKText.SubHeader>
                    <a href="https://steel-net.thyssenkrupp-steel.com/de/pride"
                        target='_blank'
                        className='inline-block px-4 mb-8 text-lg border pt-1.5 pb-0.5 border-tk-brand font-tk-medium'
                        rel="noreferrer" >
                        Zum Netzwerk pride@steel
                    </a>
                    <br />
                    <a href="mailto:pride-steel@thyssenkrupp.com" className='inline-block px-4 text-lg border pt-1.5 pb-0.5 border-tk-brand font-tk-medium'>
                        <img src={img_icon_mail} className='inline-block w-6 mr-2' alt='' />
                        pride-steel@thyssenkrupp.com
                    </a>
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <img src={img_pride} alt='' />
                </div>
            </div>
        </Section >
    )
}
