import img_preview from '../images/Mediathek/Fuehren_in_kritischen_Situationen.png';
import VideoComponent from './VideoComponent';


export default function VideoFührenInkritischenSituationen(props: { height?: number, title?: string, subtitle?: string, top?: boolean }) {
    return <VideoComponent
        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=b8f9743e-d364-444e-b9bc-7176cd22211e&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
        preview={img_preview}
        height={props.height ?? 430}
        title={props.title}
        subtitle={props.subtitle}
        top={props.top}
    />
}
