import Nav from "./Nav";

export default function SubHeader(): JSX.Element {

    return (
        <div className="hidden bg-white lg:block">
            <div className="m-auto lg:max-w-4xl xl:max-w-5xl">
                <Nav />
            </div>
        </div>
    );
}