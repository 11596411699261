import Ansprechpartner from "../components/Ansprechpartner";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import Section from "../components/Section";
import { SideLinkItem } from "../components/SideLinkItem";
import TKText from "../components/TKText";
import img_Hero from '../images/FLKZielbild/header_bg.jpg';

export default function FLKZielbildPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <TextSection />
                        <Ansprechpartner />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                        <h4 className='mb-3 text-lg'>Themenseiten</h4>
                        <SideLinkItem
                            url="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal"
                            external
                        >
                            Startseite Entwicklungsportal
                        </SideLinkItem>
                        <SideLinkItem
                            url='https://steel-net.thyssenkrupp-steel.com/de/organisation/human-resources-management-se-hrm/business-partner'
                            external
                        >
                            Team People Development
                        </SideLinkItem>
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Ein starkes Zielbild ist der Schlüssel, um Ihre<br />
                Vision in die Realität umzusetzen.
            </TKText.Header>
            <TKText.SubHeader>
                Erfahren Sie, wie Sie Ihr Zielbild klar kommunizieren, Mitarbeiter motivieren und
                Veränderungen effektiv vorantreiben können. Erfolgreiche Führung bedeutet nicht
                nur, Anweisungen zu geben, sondern ein Bild von der Zukunft zu zeichnen, das
                alle mit Leidenschaft verfolgen wollen. Die Kunst besteht darin, die richtigen
                Worte zu finden, um die Herzen und Köpfe der Menschen zu erreichen.
            </TKText.SubHeader>
        </Section >
    )
}

