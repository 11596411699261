import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import Podcast from "../components/Podcast";
import { RelevanteDokumente } from "../components/RelevanteDokumente";
import Section from "../components/Section";
import SmallTile from "../components/SmallTile";
import TKText from "../components/TKText";
import VideoComponent from "../components/VideoComponent";
import img_Hero from '../images/GutKommunizieren/hero_bg.png';
import img_video from "../images/Mediathek/1_Fuehrungsaufgabe_Regelkommunikation_durchfuehren.png";
import { RelevantDocument } from "../types/RelevantDocument";

export const Documents: RelevantDocument[] = [
    {
        title: "Leitfaden für Führungskräfte-Kommunikation",
        size: "3,09 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=baa1fb16-7b48-4c34-8ec7-bcf6824681f5"
    }
];

export const Podcasts: RelevantDocument[] = [
    {
        title: "Kommunikation",
        size: "34,7 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=7911bcd0-508c-44fa-ba9d-8594efd4785e&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
    }
];


export default function GutKommunizieren(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="px-4 lg:px-0">
                    <TextSection />
                </div>
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <VideoSection />
                        <SmallTiles />
                        <PodcastSection />
                        <RelevanteDokumente docs={Documents} />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">

                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Eine zielführende Kommunikation im Betrieb
                ist unerlässlich, um effektiv zusammenzuarbeiten
                und erfolgreich sein zu können.
            </TKText.Header>
            <TKText.SubHeader>
                Eine offene und transparente Gesprächskultur zwischen den Mitarbeitenden und
                Führungskräften fördert das Vertrauen und schafft eine positive Arbeitsatmosphäre.
                Durch klare und präzise Kommunikation werden Missverständnisse vermieden und
                Konflikte frühzeitig erkannt und gelöst.
            </TKText.SubHeader>
            <TKText.Body>
                Auch Feedback sollte regelmäßig gegeben und angenommen werden, um kontinuierlich Verbesserungen zu erzielen. Eine
                gute Kommunikation im Betrieb trägt somit maßgeblich zur Motivation und Zufriedenheit der Mitarbeitenden bei und ist ein
                wichtiger Faktor für den Unternehmenserfolg.
            </TKText.Body>
        </Section >
    )
}


function VideoSection() {
    return (
        <Section>
            <VideoComponent
                url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=10a317f6-36bc-48c9-a9ed-8628759c31e7&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                preview={img_video}
                height={400}
            />
            <div className='p-4 text-white bg-tk-brand'>
                <div className='mb-2 text-base'>
                    1. Führungsaufgabe - Regelkommunikation
                </div>
                <div className='text-sm'>
                    Shopfloormanagement<br />
                    tkPS
                </div>
            </div>
        </Section>
    );
}

function SmallTiles() {
    return (
        <Section>
            <div className="grid grid-cols-6 gap-5">
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/fuehrungskraeftekommunikation">
                    <SmallTile.Title>
                        Führungskräfte-<br />
                        kommunikation
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Erfahren Sie, wie Sie Ihre<br />
                        Botschaft klar und überzeugend<br />
                        vermitteln, um Ihre Mitarbeitenden zu
                        motivieren und zu inspirieren.<br /><br />
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/praesentationen-videos/praesentationen">
                    <SmallTile.Title>
                        Team- und
                        Schichtgespräche
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Erhalten Sie wertvolle Tipps, um<br />
                        Meetings effizient zu gestalten<br />
                        und die Beteiligung aller
                        Teammitglieder zu fördern.
                    </SmallTile.Body>
                </SmallTile>
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steeleurope.sharepoint.com/sites/as-sicherheitskulturprogramm/SitePages/Sicherheit-im-Dialog.aspx">
                    <SmallTile.Title>
                        Sicherheit im Dialog
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Wir wollen eine Dialogkultur schaffen, die unsere Arbeitssicherheit erhöht und nachhaltig verankert.
                    </SmallTile.Body>
                </SmallTile>
            </div>
        </Section>
    )
}

function PodcastSection() {
    return (
        <Section>
            <h4 className="mb-2 text-lg">Podcasts</h4>
            <div className="grid grid-cols-4 gap-3 lg:grid-cols-12">
                <div className="col-span-4">
                    <Podcast title={Podcasts[0].title} link={Podcasts[0].url} />
                </div>
            </div>
        </Section>
    );
}