import ContainerCenter from "./ContainerCenter";

function HeroIchMöchte(props: { image: string, bgClassName?: string, children: JSX.Element | JSX.Element[] }): JSX.Element {
    const style = props.image ? { backgroundImage: `url(${props.image})` } : {};
    return (
        <div className={`px-4 py-10 bg-no-repeat bg-[length:auto_150px] lg:px-0 bg-[position:right_bottom] lg:bg-[length:auto_400px] ${props.bgClassName}`} style={style}>
            <ContainerCenter>
                {props.children}
            </ContainerCenter>
        </div>
    );
}

HeroIchMöchte.Headline = ({ children, className = "text-tk-brand" }: React.PropsWithChildren<{ className?: string }>): JSX.Element => {

    return (
        <div className={`py-6 text-lg font-tk-medium ${className}`}>
            {children}
        </div>
    );
}

HeroIchMöchte.Header = ({ children, className = "text-black" }: React.PropsWithChildren<{ className?: string }>): JSX.Element => {

    return (
        <div className={`max-w-4xl pb-6 text-xl leading-snug lg:text-4xl ${className}`}>
            {children}
        </div>
    );
}

HeroIchMöchte.Body = ({ children, className = "text-tk-text-grey-light" }: React.PropsWithChildren<{ className?: string }>): JSX.Element => {

    return (
        <div className={`pb-6 leading-normal text:lg lg:text-xl ${className}`}>
            {children}
        </div>
    );
}

HeroIchMöchte.BlueBody = ({ children, className = "" }: React.PropsWithChildren<{ className?: string }>): JSX.Element => {

    return (
        <div className={`${className}`}>
            <span className="p-2 pl-0 text-base leading-loose text-white whitespace-pre-wrap font-tk-bold">
                {children}
            </span>
        </div>
    );
};

export default HeroIchMöchte;