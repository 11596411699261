import React from "react";
import Section from "./Section";

export default function Breadcrumb(props: { items: any[] }): JSX.Element {

    return (
        <Section className="p-4 text-tk-brand lg:p-0">
            {props.items.map((item, index) =>
                <React.Fragment key={index}>
                    {item}
                    {index !== props.items.length - 1 && <span> &gt; </span>}
                </React.Fragment>
            )}
        </Section>
    );
}