import { Link } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import img_Hero from "./../images/Home/Hero_image.jpg";
import img_01_Map from "./../images/Home/Icon_01_Map.svg";
import img_02_Goggle from "./../images/Home/Icon_02_Goggle.svg";
import img_03_Sign from "./../images/Home/Icon_03_Sign.svg";
import img_04_Communication from "./../images/Home/Icon_04_Communication.svg";
import img_05_entwickeln from "./../images/Home/Icon_05_entwickeln.svg";
import img_06_Backpack from "./../images/Home/Icon_06_Backpack.svg";
import img_07_Media_Library from "./../images/Home/Icon_07_Media_Library.svg";
import img_Arrow from "./../images/arrow.png";
import links from "./links";

export default function HomePage() {

  return (
    <BasicLayout>
      <HeroImage />
      <Tiles />
    </BasicLayout>
  );
}


function HeroImage() {
  return (
    <section>
      <img alt="Hero" className="w-full" src={img_Hero} />
    </section>
  )
}

function Tiles() {
  return (
    <ContainerCenter>
      <section className="grid grid-cols-1 gap-2 py-2 lg:py-10 md:grid-cols-2 lg:grid-cols-3">
        <Tile className="bg-tk-brand hover:bg-tk-brand-hover lg:col-span-2" image={img_01_Map}>
          <TilePreHeader>{"Ich möchte"}</TilePreHeader>
          <TileHeader>
            Führungsverständnis<br />
            stärken
          </TileHeader>
          <TileDescription>
            Treten Sie Ihre Führungsreise an und erfahren Sie mehr über die gelebte Führungskultur und Ihre Rolle als Führungskraft innerhalb unseres Unternehmens.
          </TileDescription>
          <TileButton linkTo={links["Führungsverständnis stärken"]} />
        </Tile>

        <Tile className="bg-tk-purple hover:bg-tk-purple-hover" imgSize={110} image={img_02_Goggle}>
          <TilePreHeader>{"Ich möchte"}</TilePreHeader>
          <TileHeader>{"mich"}<br />{"einarbeiten"}</TileHeader>
          <TileDescription>
            Einsteigen und gemeinsam <br />
            den Horizont erweitern.
          </TileDescription>
          <TileButton linkTo={links["mich einarbeiten"]} />
        </Tile>

        <Tile className="bg-tk-orange hover:bg-tk-orange-hover bg-[position:right_0px_bottom]" image={img_03_Sign}>
          <TilePreHeader>{"Ich möchte"}</TilePreHeader>
          <TileHeader>meine Aufgaben<br />kennen</TileHeader>
          <TileDescription>
            Führen Sie Ihr Team zu<br />
            neuen Bestleistungen.
          </TileDescription>
          <TileButton linkTo={links["meine Aufgaben kennen"]} />
        </Tile>

        <Tile className="lg:col-span-2 bg-tk-blue-medium hover:bg-tk-blue-medium-hover" image={img_04_Communication}>
          <TilePreHeader>{"Ich möchte"}</TilePreHeader>
          <TileHeader>{"Gespräche führen"}</TileHeader>
          <TileDescription>
            Kommunikativ auf dem Weg<br />
            zum erfolgreichen Team.
          </TileDescription>
          <TileButton linkTo={links["Gespräche führen"]} />
        </Tile>

        <Tile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" image={img_05_entwickeln}>
          <TilePreHeader>{"Ich möchte"}</TilePreHeader>
          <TileHeader>{"entwickeln"}</TileHeader>
          <TileDescription>
            Mitarbeitende und mich<br />
            selbst zu neuen Höhen<br />
            führen.
          </TileDescription>
          <TileButton linkTo={links.entwickeln} />
        </Tile>

        <Tile className="bg-tk-green hover:bg-tk-green-hover" image={img_06_Backpack}>
          <TilePreHeader>{"Ich möchte"}</TilePreHeader>
          <TileHeader>
            Werkzeuge<br />finden
          </TileHeader>
          <TileDescription>
            Für jede Herausforderung<br />
            gut gerüstet.
          </TileDescription>
          <TileButton linkTo={links["Werkzeuge finden"]} />
        </Tile>

        <Tile className="bg-tk-grey hover:bg-tk-grey-hover" image={img_07_Media_Library}>
          <TileHeader>
            Medien,<br />
            Videos &<br />
            Dokumente
          </TileHeader>
          <TileDescription>
            {""}
          </TileDescription>
          <TileButton linkTo={links["Mediathek"]} />
        </Tile>

      </section>
    </ContainerCenter>
  )
}


function Tile(props: { image?: string, imgSize?: number, children: React.ReactNode, className?: string }): JSX.Element {
  const style = props.image ? { backgroundImage: `url(${props.image})` } : {};

  return (
    <div className={`relative p-6 bg-no-repeat hover:shadow-inner bg-[length:auto_${props.imgSize ?? '160'}px] bg-[position:right_24px_bottom_20px] ${props.className}`} style={style}>
      {props.children}
    </div>
  );
}

function TilePreHeader(props: { children: React.ReactNode }) {
  return (
    <span className="block text-lg text-white p-t-3 font-tk-medium">{props.children}</span>
  )
}

function TileHeader(props: { children: React.ReactNode }) {
  return (
    <span className="block mb-4 text-3xl text-white font-tk-medium">{props.children}</span>
  )
}
function TileDescription(props: { children: React.ReactNode }) {
  return (
    <span className="block text-base text-white lg:w-96 mb-28 font-tk-bold">{props.children}</span>
  )
}
function TileButton(props: { children?: React.ReactNode, linkTo?: string }) {
  return (
    <div className="absolute block sm:bottom-0 bottom-4">
      <Link to={props.linkTo ? props.linkTo : "#"} className="">
        <span className="hidden text-xs leading-normal text-white sm:block font-tk-regular sm:border-white sm:border-solid sm:px-3 sm:py-2 sm:border">{props.children ?? "Mehr erfahren"}</span>
        <img src={img_Arrow} alt="more" className="visible block sm:invisible" />
      </Link>
    </div>
  )
}
