export default function DownloadItem(props: { item: string; size?: string; url: string; }) {

    return (
        <div className={`grid grid-cols-12 p-4 mb-1 text-sm bg-[#D9DEE8]`}>
            <a className="col-span-10 text-sm leading-none text-tk-brand" target="_blank" href={props.url} rel="noreferrer">{props.item}</a>
            {/* {props.size.length > 0 && */}
            {/* <span className="col-span-2 text-xs leading-none text-right text-tk-text-grey">{props.size}</span>} */}
        </div>
    );
}
