import { useSearchContext } from "../context/SearchContext";
import icon from "../images/search_icon.png";

export default function SearchBar(props: { className?: string }): JSX.Element {

    const context = useSearchContext();

    const search = (e: any) => {
        context.setSearchText(e.target.value);
    }

    return (
        <div className={`relative mr-0 sm:mr-4 lg:mr-0 ${props.className}`}>
            <input type="text" onKeyUp={search} placeholder={"Suche"} className="h-8 p-2 text-lg leading-none text-white border border-b border-solid outline-none placeholder:py-20 placeholder:text-lg focus:border-b-white border-tk-brand focus:placeholder:text-tk-brand placeholder:text-right focus:outline-none placeholder:text-white bg-tk-brand font-tk-medium" />
            <img src={icon} className="inline-block w-5 ml-1 " alt="" />
        </div>
    );
}