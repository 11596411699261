
const TKText = {
    Header: ({ children, className = "" }: React.PropsWithChildren<{ className?: string }>) => {
        return (
            <div className={`pb-6 text-3xl leading-snug text-black lg:text-4xl font-tk-regular ${className}`}>
                {children}
            </div>
        );
    },
    SubHeader: ({ children, className = "" }: React.PropsWithChildren<{ className?: string }>) => {

        return (
            <div className={`pb-6 text-xl leading-normal text-tk-text-grey-light font-tk-regular ${className}`}>
                {children}
            </div>
        );
    },
    Body: ({ children, className = "" }: React.PropsWithChildren<{ className?: string }>) => {

        return (
            <div className={`pb-6 text-sm leading-relaxed text-tk-text-grey ${className.indexOf('font-tk-') > -1 ? '' : 'font-tk-regular'} ${className}`}>
                {children}
            </div>
        );
    }
}

export default TKText;