import { useState } from "react";
import { Link } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import IconPodcast from "../components/IconPodcast";
import Section from "../components/Section";
import TKText from "../components/TKText";
import img_Audio_Podcast_Nr1_Kommunikation_DE from "../images/Mediathek/Audio_Podcast_Nr1_Kommunikation_DE.png";
import img_Audio_Podcast_Nr3_Positive_Leadership_DE from "../images/Mediathek/Audio_Podcast_Nr3_Positive_Leadership_DE.png";
import img_Audio_Podcast_Nr4_Fuehren_in_der_Krise_DE from "../images/Mediathek/Audio_Podcast_Nr4_Fuehren_in_der_Krise_DE.png";
import img_Audio_Podcast_Nr6_Resilienz_DE from "../images/Mediathek/Audio_Podcast_Nr6_Resilienz_DE.png";
import img_Fuehren_in_kritischen_Situationen from "../images/Mediathek/Fuehren_in_kritischen_Situationen.png";
import img_Kommunikationsleitfaden_fuer_Fuehrungskraefte_2 from "../images/Mediathek/Kommunikationsleitfaden fuer Fuehrungskraefte_2.Auflage.png";
import img_Transformationsnotwendigkeit from "../images/Mediathek/Transformationsnotwendigkeit_-_Vielfalt_im_DFB.jpeg";
import img_Busemann_Lead_yourself from "../images/Mediathek/Video Busemann Lead yourself.png";
import img_Wie_Potenzial_zur_Staerke_wird from "../images/Mediathek/Wie_Potenzial_zur_Staerke_wird.png";
import img_das_geheimnis_der_motivation from "../images/Mediathek/das_geheimnis_der_motivation.png";
import img_feedback_geben_und_nehmen_video_preview from "../images/Mediathek/feedback_geben_und_nehmen_video_preview.png";
import icon_pfd from "../images/Mediathek/icon_pdf.svg";
import icon_video from "../images/Mediathek/icon_video.svg";
import img_lernen_und_selbstreflexion from "../images/Mediathek/lernen_und_selbstreflexion_-_warum_menschen_oft_an_ihren_staerken_scheitern_und_wie_sie_das_verhindern.png";
import img_mitarbeiterentwicklung from "../images/Mediathek/mitarbeiterentwicklung_-_so_machen_sie_ihr_team_staerker.png";
import img_veraenderungen_in_ihrem_team_umsetzen from "../images/Mediathek/veraenderungen_in_ihrem_team_umsetzen(1080p).png";
import { RelevantDocument } from "../types/RelevantDocument";
import { Documents as Documents_BewerbungsgesprächeFühren } from "./BewerbungsgesprächeFühren";
import { Documents as Documents_FeedbackNehmenUndGeben } from "./FeedbackNehmenUndGeben";
import { Documents as Documents_FührungsUndLeistungskulturPage } from "./FührungsUndLeistungskulturPage";
import { Documents as Documents_MeineRolleAlsFührungskraftPage } from "./MeineRolleAlsFührungskraftPage";
import { Documents as Documents_MichEntwickelnPage } from "./MichEntwickelnPage";


export enum MediaType {
    Video,
    PDF,
    Podcast
};

type Media = {
    title: string,
    image: string,
    url: string,
    size?: string,
    type: MediaType
}


function makePDFFromRelevanteDocuments(docs: RelevantDocument[]): Media[] {
    return docs.map(doc => {
        return {
            title: doc.title,
            image: doc.image ?? "",
            url: doc.url,
            size: doc.size,
            type: MediaType.PDF
        }
    });
}


export const files: Media[] = [
    {
        title: "Führen in kritischen Situationen - handout.pdf",
        image: img_Fuehren_in_kritischen_Situationen,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=a840ad88-6ab5-4cb6-8f47-810d34faf88b",
        size: "11,57 MB",
        type: MediaType.PDF
    },
    {
        title: "Kommunikationsleitfaden fuer Fuehrungskraefte_2.Auflage.pdf",
        image: img_Kommunikationsleitfaden_fuer_Fuehrungskraefte_2,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=baa1fb16-7b48-4c34-8ec7-bcf6824681f5",
        size: "3,09 MB",
        type: MediaType.PDF
    },
    ...makePDFFromRelevanteDocuments(Documents_MeineRolleAlsFührungskraftPage),
    ...makePDFFromRelevanteDocuments([Documents_FührungsUndLeistungskulturPage[0]]),
    ...makePDFFromRelevanteDocuments(Documents_FeedbackNehmenUndGeben),
    ...makePDFFromRelevanteDocuments(Documents_BewerbungsgesprächeFühren),
    ...makePDFFromRelevanteDocuments([Documents_MichEntwickelnPage[1]]),
    ...makePDFFromRelevanteDocuments([Documents_MichEntwickelnPage[0]]),
    {
        title: "Busemann: Lead yourself",
        image: img_Busemann_Lead_yourself,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=59f32513-7dbe-4734-813e-bb73644f5ff5&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "351,96 MB",
        type: MediaType.Video
    },
    {
        title: "Wie Potenzial zur Stärke wird",
        image: img_Wie_Potenzial_zur_Staerke_wird,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=a0d01be6-93b9-41f2-8b41-83e737ded8f1&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "564,02 MB",
        type: MediaType.Video
    },
    {
        title: "Das Geheimnis der Motivation - So haben Sie einen mobilisierenden Einfluss auf Ihr Team - in guten und in schlechten Zeiten",
        image: img_das_geheimnis_der_motivation,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=9f5d0a60-8e47-4539-a450-f33e34568c5d&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "297,25 MB",
        type: MediaType.Video
    },
    {
        title: "Lernen und Selbstreflexion - Warum Menschen oft an ihren Stärken scheitern und wie Sie das verhindern",
        image: img_lernen_und_selbstreflexion,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=5e679dc9-71ce-4807-b862-a5efaa347b85&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "395,06 MB",
        type: MediaType.Video
    },
    {
        title: "Mitarbeiterentwicklung - So machen Sie Ihr Team stärker",
        image: img_mitarbeiterentwicklung,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=1858ddec-cd14-478b-ad98-702a67c3efcf&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "295,91 MB",
        type: MediaType.Video
    },
    {
        title: "Veränderungen in Ihrem Team umsetzen - So bringen Sie Ihr Team und Ihre Teamkultur positiv nach vorn",
        image: img_veraenderungen_in_ihrem_team_umsetzen,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=1b4a117f-ce57-4fe8-866a-9fef52ae106c&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "329,09 MB",
        type: MediaType.Video
    },
    {
        title: "Führen in kritischen Situationen",
        image: img_Fuehren_in_kritischen_Situationen,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=b8f9743e-d364-444e-b9bc-7176cd22211e&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "656,77 MB",
        type: MediaType.Video
    },
    {
        title: "Feedback geben und nehmen",
        image: img_feedback_geben_und_nehmen_video_preview,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=4bc6233d-5e7d-434c-9079-28d32b8bbc45&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create",
        size: "299,82 MB",
        type: MediaType.Video
    },
    {
        title: "Transformationsnotwendigkeit & Vielfalt im DFB – was wir bei Steel daraus lernen können",
        image: img_Transformationsnotwendigkeit,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=3f3674ed-a21c-4112-a5df-b15ac0939aed&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create",
        size: "5,76 GB",
        type: MediaType.Video
    },
    {
        title: "Kommunikation DE",
        image: img_Audio_Podcast_Nr1_Kommunikation_DE,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=7911bcd0-508c-44fa-ba9d-8594efd4785e&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create",
        size: "36,4 MB",
        type: MediaType.Podcast
    },
    {
        title: "Positive Leadership DE",
        image: img_Audio_Podcast_Nr3_Positive_Leadership_DE,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=4e1523a3-5fe3-423d-9c8e-9abbe2b08479&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "55,6 MB",
        type: MediaType.Podcast
    },
    {
        title: "Führen in der Krise DE",
        image: img_Audio_Podcast_Nr4_Fuehren_in_der_Krise_DE,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=cb7821af-6162-4643-9601-94156f5d3968&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "46,1 MB",
        type: MediaType.Podcast
    },
    {
        title: "Resilienz DE",
        image: img_Audio_Podcast_Nr6_Resilienz_DE,
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=c726eb1c-2ec2-4d93-a466-379f079a4315&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
        size: "45,2 MB",
        type: MediaType.Podcast
    },
];

export default function MediathekPage(props: {}): JSX.Element {

    const [search, setSearch] = useState<string>("");

    return (
        <BasicLayout>
            <ContainerCenter>

                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 px-4 pt-12 lg:px-0 lg:col-span-8">
                        <TKText.Header>Mediathek</TKText.Header>
                        <div className="my-8">
                            <input type="text" onChange={(e) => setSearch(e.target.value)} className="w-full h-12 px-4 font-sans text-base border border-tk-grey" placeholder={"Suchbegriff eingeben"} />
                        </div>
                        <LibrarySection title="PDF Dateien" items={files.filter(i => i.type === MediaType.PDF).filter(i => !search.length || i.title.toLowerCase().indexOf(search.toLowerCase()) > -1)} />
                        <LibrarySection title="Videos" items={files.filter(i => i.type === MediaType.Video).filter(i => !search.length || i.title.toLowerCase().indexOf(search.toLowerCase()) > -1)} />
                        <LibrarySection title="Podcasts" items={files.filter(i => i.type === MediaType.Podcast).filter(i => !search.length || i.title.toLowerCase().indexOf(search.toLowerCase()) > -1)} />
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}


function LibrarySection(props: { title: string, items: Media[] }): JSX.Element {
    return (
        <Section>
            <TKText.Header>{props.title}</TKText.Header>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-2 gap-y-6">
                {props.items.map((item, index) => <MediaTile item={item} />)}
            </div>
        </Section>
    );
}

function MediaTile(props: { item: Media }): JSX.Element {
    const item = props.item;
    return (
        <Link to={item.url} target="_blank" className="col-span-1 group">
            <div className="flex items-center h-32 p-2 mb-1 bg-tk-grey-light">
                {item.type === MediaType.PDF &&
                    (item.image.length
                        ? <img className="m-auto" src={item.image} alt={item.title} />
                        : <img className="w-16 m-auto opacity-60 group-hover:opacity-100" src={icon_pfd} alt={item.title} />
                    )}
                {item.type === MediaType.Video && (
                    <div className="flex items-center w-full h-full bg-tk-grey-hover">
                        {item.image.length
                            ? <img className="m-auto" src={item.image} alt={item.title} />
                            : <img className="w-16 m-auto opacity-60 group-hover:opacity-100" src={icon_video} alt={item.title} />
                        }
                    </div>
                )}
                {item.type === MediaType.Podcast && (
                    item.image.length
                        ? <img className="m-auto" src={item.image} alt={item.title} />
                        : <IconPodcast className="w-8 m-auto fill-black opacity-60" />
                )}
            </div>
            <div className="relative h-32 px-2 pt-4 bg-tk-grey-light">
                <span className="text-sm underline break-words text-tk-text-grey-light">{item.title}</span>
                <span className="absolute text-xs bottom-2 right-2 text-tk-brand">{item.size}</span>
            </div>
        </Link>
    );
}