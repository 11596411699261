const config = {
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    tenantId: process.env.REACT_APP_TENANT_ID,
    apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
    apiName: process.env.REACT_APP_FUNC_NAME,
    teamsAppId: process.env.REACT_APP_TEAMS_APP_ID,
    scopes: [
        'User.Read',
    ]
}

export default config;
