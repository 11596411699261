import { ReactNode } from "react";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroIchMöchte from "../components/HeroIchMöchte";
import Section from "../components/Section";
import Tile, { TileButton } from "../components/Tile";
import VideoComponent from "../components/VideoComponent";
import makeTileDataToSearchItem from "../helpers/makeTileDataToSearchItem";
import img_Hero from '../images/MichEinarbeiten/header_bg.svg';
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";
import { TileData } from "../types/TileData";
import img_01 from "./../images/MichEinarbeiten/Einarbeiten_01.jpg";
import img_02 from "./../images/MichEinarbeiten/Einarbeiten_02.jpg";
import img_05 from "./../images/MichEinarbeiten/Einarbeiten_05.png";
import img_Leitfaden_Schiko from "./../images/MichEinarbeiten/Leitfaden_Schiko.jpg";
import video_preview from "./../images/MichEinarbeiten/video_preview.jpg";
import links from "./links";

const TilesData: TileData[] = [
    {
        title: "Teamleitende",
        link: "https://wd3.myworkday.com/steeleurope/learning/program/9771346dcb4e1001e53baa96e9d50000?type=2d29754fdb8e100008b50ff6bc94003b",
        external: true,
        description: `
        Ein spannender Mix aus Theorie und Praxis zeigt Ihnen, wie Sie die anspruchsvolle Position als Führungskraft verantwortungsvoll, mutig, offen und wertschätzend ausfüllen.
        `,
    },
    {
        title: "Meine Rolle als Führungskraft",
        link: links["Meine Rolle als Führungskraft"],
        external: false,
        description: `
            Wählen Sie aus modernen Führungsmethoden Ihr persönliches Rüstzeug und
            entwickeln Sie ein visionäres Mindset, um Ihr
            Team auf jedem Terrain erfolgreich zu leiten.
        `
    },
    {
        title: "Führungs- & Leistungskultur",
        link: links["Führungs und Leistungskultur"],
        external: false,
        description: `
            Führungskräfte sind die Wegbereiter für den Erfolg
            eines Unternehmens. Sie lenken die Abläufe,
            motivieren Mitarbeitende und gestalten die Vision
            des Unternehmens. Doch welche Ziele werden
            im Detail verfolgt?
        `
    },
    {
        title: "Werkzeuge finden",
        link: links["Werkzeuge finden"],
        external: false,
        description: `
            Die Übersicht der Aufgaben als neue Führungskraft kann komplex werden. Um die Übersicht zu behalten, haben wir eine Reihe von Werkzeugen und Tools zusammengefasst, um Ihre Führungskompetenz nachhaltig zu verstärken.
        `
    },
    {
        title: "Coach Hub",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/digitales-coaching",
        external: true,
        description: `
            Jede neue Führungskraft hat die Möglichkeit, zur Unterstützung auf dem Weg in die Führungsrolle ein digitales Coaching in Anspruch zu nehmen.
        `
    }
];

export const SearchLinks: SearchItem[] = [
    {
        title: "Mich einarbeiten",
        link: links["mich einarbeiten"],
        description: "Einsteigen und den Horizont erweitern",
        type: SearchItemType.InternalLink
    },
    {
        title: "Schichtkoordinierende",
        link: "https://wd3.myworkday.com/steeleurope/learning/program/c3d08f96c6eb1001e267a630c88a0000?type=2d29754fdb8e100008b50ff6bc94003b",
        type: SearchItemType.ExternalLink,
        description: TilesData[0].description,
    },
    ...TilesData.map(tileData => makeTileDataToSearchItem(tileData)),
];

export default function MichEinarbeitenPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <HeroImage />
            <ContainerCenter>
                <Tiles />
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <HeroIchMöchte image={img_Hero} bgClassName="bg-tk-purple">
                <HeroIchMöchte.Headline className="text-white">Ich möchte: Mich einarbeiten</HeroIchMöchte.Headline>
                <HeroIchMöchte.Header className="text-white">
                    Einsteigen und den Horizont erweitern
                </HeroIchMöchte.Header>
                <HeroIchMöchte.Body className="text-white">
                    Sie sind kurz vor der Übernahme der ersten Führungsfunktion oder neu in der<br />
                    Führungsrolle und möchten sich einen ersten Überblick verschaffen, einen Plan für<br />
                    die nächsten Wochen schmieden. Hier finden Sie eine Zusammenfassung von<br />
                    nützlichen Informationen für Ihren erfolgreichen Einstieg.
                </HeroIchMöchte.Body>
                <HeroIchMöchte.BlueBody className="mb-14">
                    Mit Karte und Kompass gehen Sie effektiv und sicher auf diese Reise
                </HeroIchMöchte.BlueBody>
            </HeroIchMöchte>
        </section>
    )
}

function Tiles() {
    return (
        <Section>
            <div className="grid grid-cols-12 gap-0">
                <Tile double image={img_01} />
                <SpecialTile double className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[0].link}
                    linkToText={TilesData[0].title}
                    external={TilesData[0].external}
                    linkTo2={SearchLinks[1].link}
                    linkTo2Text={SearchLinks[1].title}
                    external2={SearchLinks[1].type === SearchItemType.ExternalLink}
                    extraText2Link="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/meine-weiterentwicklung-qualifizierung/wissensmanagement/leitfaden-fuer-schichtkoordinatoren"
                    title="tk Steel Leadership Essentials">
                    {TilesData[0].description}
                </SpecialTile>
                {/*  */}
                <Tile className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[1].link}
                    external={TilesData[1].external}
                    title={TilesData[1].title}
                >
                    {TilesData[1].description}
                </Tile>
                <Tile className="bg-tk-purple hover:bg-tk-purple-hover"
                    linkTo={TilesData[2].link}
                    external={TilesData[2].external}
                    title={TilesData[2].title}
                >
                    {TilesData[2].description}
                </Tile>
                <Tile image={img_02} />
                {/*  */}
                <div className="col-span-12">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=1b4a117f-ce57-4fe8-866a-9fef52ae106c&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={video_preview}
                        height={420}
                        title="Veränderungen in Ihrem Team umsetzen"
                        subtitle="Michael Paschen, Geschäftsführer Profil M GmbH"
                    />
                </div>
                {/*  */}
                <Tile className="bg-tk-blue-light hover:bg-tk-blue-light-hover"
                    linkTo={TilesData[3].link}
                    external={TilesData[3].external}
                    title={TilesData[3].title}
                >
                    {TilesData[3].description}
                </Tile>
                <Tile image={img_05} />
                <Tile className="bg-tk-blue-medium hover:bg-tk-blue-medium-hover"
                    linkTo={TilesData[4].link}
                    external={TilesData[4].external}
                    title={TilesData[4].title}
                >
                    {TilesData[4].description}
                </Tile>
            </div>
        </Section>
    );
}

type SpecialTileProps = {
    double?: boolean;
    children?: ReactNode;
    image?: string;
    title?: React.ReactNode;
    body?: string;
    linkTo?: string;
    linkToText?: string;
    linkTo2?: string;
    linkTo2Text?: string;
    linkTo2Border?: boolean;
    extraText2?: string;
    extraText2Link?: string;
    className?: string;
    external?: boolean;
    external2?: boolean;
}

function SpecialTile(props: SpecialTileProps) {

    const size = props.double ? 'col-span-12 lg:col-span-6' : 'col-span-12 lg:col-span-4';

    if (props.image) {
        return (
            <div
                className={`min-w-full bg-no-repeat bg-cover bg-[position:center_center] ${size} ${props.className}`}
                style={{ backgroundImage: `url(${props.image})` }}>
            </div>
        );
    }

    return (
        <div className={`flex flex-col pr-0 text-white p-7 min-h-[20rem] ${size} ${props.className}`}>
            <div className="mb-6 text-2xl font-tk-medium ">{props.title}</div>
            <div className="flex-wrap max-w-sm mb-12 text-sm leading-6">{props.children}</div>
            <div className="relative flex">
                <div className="basis-1/2">
                    <div className="relative flex flex-col items-start ">
                        <TileButton linkTo={props.linkTo} className="mb-2" external={props.external}>{props.linkToText}</TileButton>
                        <TileButton linkTo={props.linkTo2} external={props.external2}>{props.linkTo2Text}</TileButton>
                    </div>
                </div>
                <div className="basis-1/2">
                    <a className="flex items-end" href={props.extraText2Link} target="_blank" rel="noreferrer" >
                        <img src={img_Leitfaden_Schiko} alt="Leitfaden Schiko" className="inline-block w-16 " />
                        <span className="flex-1 inline-block bg-white text-tk-brand">
                            Hier erhalten Sie den <br />
                            Leitfaden für<br />
                            Schichtkoordinierende {">"}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}

