import { useEffect, useState } from "react";

type ContextMenuPosition = {
    x: number,
    y: number,
}

const useContextMenu = () => {

    const [contextMenuPosition, setContextMenuPosition] = useState<ContextMenuPosition | null>();

    useEffect(() => {
        const handleClick = () => setContextMenuPosition(null);
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    return {
        contextMenuPosition,
        setContextMenuPosition,
    };
};

export default useContextMenu;