export default function IconExternalLink(props: { className: string }): JSX.Element {

    return (
        <svg
            className={props.className}
            data-name="Gruppe 15519" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
            <defs>
                <clipPath id="a">
                    <path data-name="Rechteck 2186" fill="none" d="M0 0h15v15H0z" />
                </clipPath>
            </defs>
            <g data-name="Gruppe 15518" clipPath="url(#a)">
                <path data-name="Pfad 3642" d="M8.864 1.364a.682.682 0 1 1 0-1.365h5.455A.682.682 0 0 1 15 .682v5.454a.682.682 0 0 1-1.364 0V2.328L6.618 9.346a.682.682 0 0 1-.964-.964l7.018-7.018ZM0 3.409a.682.682 0 0 1 .682-.682h4.091a.682.682 0 0 1 0 1.364H1.364v9.545h9.545v-3.409a.682.682 0 1 1 1.364 0v4.091a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 14.318Z" fillRule="evenodd" />
            </g>
        </svg>
    );
}