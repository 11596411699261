import Ansprechpartner from "../components/Ansprechpartner";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import { RelevanteDokumente } from "../components/RelevanteDokumente";
import Section from "../components/Section";
import TKText from "../components/TKText";
import VideoComponent from "../components/VideoComponent";
import img_Hero from '../images/TeamEntwickeln/header_bg.png';
import video_preview from '../images/TeamEntwickeln/video_preview.png';
import { RelevantDocument } from "../types/RelevantDocument";

export const Documents: RelevantDocument[] = [
    {
        title: "Führen in kritischen Situationen",
        size: "12,1 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=a840ad88-6ab5-4cb6-8f47-810d34faf88b"
    },
    {
        title: "Impulsvortrag: Mitarbeitende entwickeln",
        size: "1,4 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=f72e7f33-1ae0-4e8b-ac79-b69f8ae5d9d7"
    },
    {
        title: "Impulsvortrag: Motivation",
        size: "1,7 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=f1f9e483-6abf-4d09-8a12-fa1f36c28c09"
    },
    {
        title: "Wie Potenzial zur Stärke wird_DE",
        size: "2,4 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=abc65696-07f3-4261-9a5e-4667d7dae31c"
    },
];

export default function TeamEntwickelnPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <TextSection />
                        <VideoSection />
                        <RelevanteDokumente docs={Documents} />
                        <Ansprechpartner />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                        <h4 className='mb-3 text-lg'>Weiterführende Links</h4>
                        <a
                            // target="_blank"
                            href='#1'
                            className='block w-full py-3 border-b border-solid text-tk-brand border-b-tk-grey' rel="noreferrer">
                            Beispiellink 1
                        </a>
                        <a
                            // target="_blank"
                            href='#2'
                            className='block w-full py-3 border-b border-solid text-tk-brand border-b-tk-grey' rel="noreferrer">
                            Beispiellink 2
                        </a>
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section>
            <TKText.Header>
                Lorem ipsum dolor sit amet, consetetur<br />
                sadipscing elitr, sed diam nonumy eirmod<br />
                tempor invidunt ut labore
            </TKText.Header>
            <TKText.SubHeader>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                vero eos et accusam et justo duo.
            </TKText.SubHeader>
            <TKText.Body>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctu
            </TKText.Body>
        </Section >
    )
}

function VideoSection() {
    return (
        <Section>
            <VideoComponent
                url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=b8f9743e-d364-444e-b9bc-7176cd22211e&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                preview={video_preview}
            />
        </Section>
    );
}