import { app } from "@microsoft/teams-js";
import { useTeamsFx } from "@microsoft/teamsfx-react";
import { MouseEvent } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useSearchContext } from "../context/SearchContext";
import useContextMenu from "../hooks/useContextMenu";
import MenuContextContainer from "./MenuContextContainer";

export default function NavLinkWithContextMenu(props: NavLinkProps) {
    const { inTeams } = useTeamsFx();
    const searchContext = useSearchContext();
    const { contextMenuPosition, setContextMenuPosition } = useContextMenu();

    const onContextMenuHandler = async (e: MouseEvent<Element>) => {
        if (!inTeams) return;

        e.preventDefault();

        console.log((await app.getContext()).page.subPageId);

        setContextMenuPosition({
            x: e.pageX,
            y: e.pageY,
        });

    }

    return (
        <NavLink
            onContextMenu={onContextMenuHandler}
            onMouseLeave={(e) => setContextMenuPosition(null)}
            to={props.to} className={props.className} onClick={() => searchContext.reset()}>
            {typeof props.children !== 'function' && props.children}
            {contextMenuPosition && <MenuContextContainer top={contextMenuPosition.y} left={contextMenuPosition.x} url={props.to} />}
        </NavLink>
    );
}
