import Fuse from "fuse.js";
import ContainerCenter from "../components/ContainerCenter";
import IconExternalLink from "../components/IconExternalLink";
import IconInternalLink from "../components/IconInternalLink";
import IconPodcast from "../components/IconPodcast";
import { useSearchContext } from "../context/SearchContext";
import icon_pdf from "../images/Mediathek/icon_pdf.svg";
import icon_video from "../images/Mediathek/icon_video.svg";
import icon_search from "../images/Search/search_icon.png";
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";
export default function SearchPage(props: {}): JSX.Element {
    const searchContext = useSearchContext();

    return (
        <>

            <ContainerCenter>
                <div className="mt-10 mb-4">
                    <img src={icon_search} className="inline-block w-10 mr-1 " alt="search icon" />
                    <span className="text-xl text-black">{searchContext.searchText}</span>
                </div>
                <div className="px-2 mb-4 text-base text-tk-text-grey-light">
                    {searchContext.results.length} Ergebnisse
                </div>
                <>{searchContext.results.map(item => <Result result={item} />)}</>
            </ContainerCenter>

        </>
    );
}


function Result(props: { result: Fuse.FuseResult<SearchItem> }) {
    const item = props.result.item;
    return (
        <div className="bg-[#F7F7F7] p-4 mb-4">
            <div>
                <a className="text-lg text-tk-brand hover:underline" href={item.link} target={item.type === SearchItemType.InternalLink ? "_self" : "_blank"} rel="noreferrer">
                    <ResultIcon type={item.type} />{item.title}
                </a>
            </div>
            <div className="text-sm break-words text-tk-text-grey-light">{item.link}</div>
            {item.description.length > 0 && <div className="mt-2 text-sm" >{item.description}</div>}
        </div>
    )
}

function ResultIcon(props: { type: SearchItemType }) {
    let icon = <></>;

    switch (props.type) {
        case SearchItemType.Video:
            icon = <img src={icon_video} className="inline-block" alt="video icon" />;
            break;
        case SearchItemType.PDF:
            icon = <img src={icon_pdf} className="inline-block" alt="pdf icon" />;
            break;
        case SearchItemType.Podcast:
            icon = <IconPodcast className="m-auto w-3.5 fill-tk-brand" />;
            break;
        case SearchItemType.ExternalLink:
            icon = <IconExternalLink className="w-4 m-auto mt-1 fill-tk-brand" />;
            break;
        case SearchItemType.InternalLink:
            icon = <IconInternalLink className="w-5 m-auto mt-1 fill-tk-brand" />;
            break;
    }

    return (
        <div className="inline-block w-5 h-5 mr-2">
            {icon}
        </div>
    );
}