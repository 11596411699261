import { Link } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import { RelevanteDokumente } from "../components/RelevanteDokumente";
import Section from "../components/Section";
import SmallTile from "../components/SmallTile";
import TKText from "../components/TKText";
import VideoComponent from "../components/VideoComponent";
import { VideoDescription } from "../components/VideoDescription";
import video_preview_02 from '../images/Mediathek/Wie_Potenzial_zur_Staerke_wird.png';
import img_preview from "../images/Mediathek/das_geheimnis_der_motivation.png";
import img_preview2 from "../images/Mediathek/lernen_und_selbstreflexion_-_warum_menschen_oft_an_ihren_staerken_scheitern_und_wie_sie_das_verhindern.png";
import img_Hero from '../images/MitarbeitendeEntwickeln/header_bg.jpg';
import video_preview_01 from '../images/MitarbeitendeEntwickeln/video_preview_01.png';
import { RelevantDocument } from "../types/RelevantDocument";

export const Documents: RelevantDocument[] = [
    {
        title: "Wie entstehen Stärken - Kompetenz und Potenzial",
        size: "1,1 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=2bc503a1-5d42-4c55-a68c-8b17b414352b"
    },
    {
        title: "Wie Potenzial zur Stärke wird_DE",
        size: "2,4 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=abc65696-07f3-4261-9a5e-4667d7dae31c"
    },
];

export default function MitarbeitendeEntwickelnPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImage />
                <TextSection />
                <VideoSection />
                <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                    <div className="col-span-12 lg:col-span-8">
                        <SmallTiles />
                        <RelevanteDokumente docs={[Documents[0]]} />
                    </div>
                    <div className="hidden lg:col-span-1 lg:block">
                    </div>
                    <div className="col-span-12 lg:col-span-3">
                    </div>
                </div>
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <img className="w-full" src={img_Hero} alt="Feedback nehmen und geben" />
        </section>
    )
}

function TextSection() {
    return (
        <Section className="px-4 lg:px-0">
            <TKText.Header>
                Ein erfolgreiches Unternehmen zeichnet sich
                nicht nur durch innovative Produkte und
                Dienstleistungen aus, sondern auch durch
                hochqualifizierte und motivierte Mitarbeitende.
            </TKText.Header>
            <TKText.SubHeader>
                Die kontinuierliche Entwicklung der Mitarbeitenden ist daher von entscheidender
                Bedeutung für den langfristigen Erfolg einer Organisation. Mitarbeiterentwicklung
                bezieht sich auf gezielte Maßnahmen und Programme, die darauf abzielen, die
                Fähigkeiten, das Wissen und das Potenzial der Mitarbeitenden zu fördern und zu
                erweitern.
            </TKText.SubHeader>
        </Section >
    )
}

function VideoSection() {
    return (
        <Section>
            <div className="grid grid-cols-1 gap-5 p-4 lg:grid-cols-3 lg:p-0">
                <div className="col-span-1">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=9f5d0a60-8e47-4539-a450-f33e34568c5d&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={img_preview}
                        height={180}
                    />
                    <VideoDescription title={"Das Geheimnis der Motivation"}>
                        <br />
                        Michael Paschen<br />
                        Geschäftsführer Profil M GmbH
                    </VideoDescription>
                </div>
                <div className="col-span-1">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=5e679dc9-71ce-4807-b862-a5efaa347b85&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={img_preview2}
                        height={180}
                    />
                    <VideoDescription title={"Warum Menschen oft an Ihren Stärken scheitern und wie Sie das verhindern"}>
                        Michael Paschen<br />
                        Geschäftsführer Profil M GmbH
                    </VideoDescription>
                </div>
                <div className="col-span-1">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=1858ddec-cd14-478b-ad98-702a67c3efcf&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={video_preview_01}
                        height={180}
                    />
                    <VideoDescription title={"So machen Sie Ihr Team stärker"}>
                        <br />
                        Michael Paschen<br />
                        Geschäftsführer Profil M GmbH
                    </VideoDescription>
                </div>
                <div className="col-span-1">
                    <VideoComponent
                        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=a0d01be6-93b9-41f2-8b41-83e737ded8f1&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create"
                        preview={video_preview_02}
                        height={200}
                    />
                    <VideoDescription title="Wie Potenzial zur Stärke wird">
                        Michael Paschen<br />
                        Geschäftsführer Profil M GmbH<br />
                        <div className="grid grid-cols-10 mt-4 mb-2 text-xs">
                            <div className="col-span-7">
                                Handout zum Video:
                                <br />
                                <Link className="text-white underline " to="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=abc65696-07f3-4261-9a5e-4667d7dae31c" target="_blank">
                                    Wie Potenzial zur Stärke wird
                                </Link>
                            </div>
                            <span className="col-span-3 text-right">
                                <br />
                                pdf | 2,4 MB
                            </span>
                        </div>
                    </VideoDescription>
                </div>
            </div>
        </Section>
    )
}

function SmallTiles() {
    return (
        <Section>
            <div className="grid grid-cols-6 gap-5">
                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal">
                    <SmallTile.Title>
                        Entwicklungsportal
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Eine hilfreiche Sammlung von
                        Dokumenten, Artikeln und vielen
                        weiteren Aspekten der
                        persönlichen Entwicklung.
                    </SmallTile.Body>
                </SmallTile>

                <SmallTile className="bg-tk-blue-light hover:bg-tk-blue-light-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/grundlagen/lupsteel">
                    <SmallTile.Title>
                        LuP@Steel
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Mehr zu unserem
                        integrierten Talent- & Performance-Prozess zur
                        Leistungs- und Potenzialentwicklung.
                    </SmallTile.Body>
                </SmallTile>

                <SmallTile className="bg-tk-brand hover:bg-tk-brand-hover" linkTo="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/entwicklungsportal/entwickeln/entwicklungsplan">
                    <SmallTile.Title>
                        Entwicklungsplan
                    </SmallTile.Title>
                    <SmallTile.Body>
                        Ein gemeinsames Tool für Führungskräfte und Mitarbeitende in Workday, um Entwicklungsmaßnahmen zu vereinbaren und festzuhalten.
                    </SmallTile.Body>
                </SmallTile>
            </div>
        </Section>
    )
}

