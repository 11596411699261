import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom";
import App from "./components/App";
import config from "./components/config";
import './i18n';
import "./index.css";


const msalConfig: Configuration = {
    auth: {
        clientId: config.clientId!,
        authority: `https://login.microsoftonline.com/${config.tenantId}/`,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
}

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(<MsalProvider instance={pca}><App /></MsalProvider>, document.getElementById("root"));
