import img_preview from '../images/Mediathek/feedback_geben_und_nehmen_video_preview.png';
import VideoComponent from './VideoComponent';

export default function VideoFeedbackGebenUndNehmen(props: { height?: number, title?: string, subtitle?: React.ReactNode }) {
    return <VideoComponent
        url="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/embed.aspx?UniqueId=4bc6233d-5e7d-434c-9079-28d32b8bbc45&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
        preview={img_preview}
        height={props.height ?? 430}
        title={props.title}
        subtitle={props.subtitle}
    />
}
