import { app } from "@microsoft/teams-js";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TeamsFxContext } from "../components/Context";
import links from "./links";

export default function WildcardPage(props: {}): JSX.Element {
    const navigate = useNavigate();
    const { inTeams } = useContext(TeamsFxContext)

    useEffect(() => {
        if (inTeams)
            app
                .getContext()
                .then(c => {
                    if (c.page.subPageId) {
                        console.log("Navigate to:", c.page.subPageId);
                        navigate(c.page.subPageId)
                    }
                    else {
                        console.log("Navigate to:", links.home);
                        navigate(links.home)
                    }
                })
                .catch(e => {
                    console.log(e);
                    console.log("Navigate with error to:", links.home, e);
                    navigate(links.home)
                })
        else {
            console.log("Navigate NOT IN TEAMS to:", links.home);
            navigate(links.home)
        }

    }, [inTeams, navigate])

    return (
        <>

        </>
    );
}