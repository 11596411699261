import { Link } from "react-router-dom";
import img_arrow from "../images/arrow.png";
import IconExternalLink from "./IconExternalLink";

const SmallTile = (props: { linkTo: string, className: string, children: React.ReactNode }): JSX.Element => {
    const isExternalLink = props.linkTo.indexOf('https://') > -1;

    return (
        <Link
            to={props.linkTo}
            target={isExternalLink ? "_blank" : "_self"}
            className={`relative col-span-6 p-4 border border-solid select-none lg:col-span-2 border-tk-brand ${props.className}`}>
            {props.children}
            <div className="absolute w-4 bottom-3 right-4 ">
                {!isExternalLink && <img src={img_arrow} className="" alt="" />}
                {isExternalLink && <IconExternalLink className="fill-white" />}
            </div>
        </Link >
    );
}

SmallTile.Title = (props: { children: React.ReactNode, style?: any }): JSX.Element => {
    return (
        <div className="pb-4 text-2xl leading-tight text-white" style={props.style}>{props.children}</div>
    )
}

SmallTile.Body = (props: { children: React.ReactNode }): JSX.Element => {
    return (
        <div className="text-xs leading-relaxed text-white min-h-[84px]">{props.children}</div>
    )
}


export default SmallTile