import links from "../pages/links";
import { SideLinkItem } from "./SideLinkItem";

export default function FührungsSideLinks(props: {}): JSX.Element {

    return (
        <>
            <h4 className='mb-3 text-lg'>Themenseiten</h4>
            <SideLinkItem url={links["Führungsverständnis stärken"]}>
                Führungsverständnis stärken
            </SideLinkItem>
            <SideLinkItem url={links["Führungs und Leistungskultur"]}>
                Führungs- & Leistungskultur
            </SideLinkItem>
            <SideLinkItem url={links["Zielbildarbeit in Bereichen und der strategische Treiber FLK"]}>
                Zielbildarbeit in Bereichen und der strategische Treiber FLK
            </SideLinkItem>
            {/* <SideLinkItem url={links["FLK-Dialog"]}>
                FLK-Dialog
            </SideLinkItem> */}
        </>
    );
}