import BasicLayout from "../components/BasicLayout";
import ContainerCenter from "../components/ContainerCenter";
import HeroIchMöchte from "../components/HeroIchMöchte";
import Section from "../components/Section";
import Tile from "../components/Tile";
import makeTileDataToSearchItem from "../helpers/makeTileDataToSearchItem";
import img_Hero from '../images/MeineAufgabenKennen/hero_bg.svg';
import { SearchItem } from "../types/SearchItem";
import { SearchItemType } from "../types/SearchItemType";
import { TileData } from "../types/TileData";
import img_01 from "./../images/MeineAufgabenKennen/Aufgaben_01.jpg";
import img_02 from "./../images/MeineAufgabenKennen/Aufgaben_02.jpg";
import img_03 from "./../images/MeineAufgabenKennen/Aufgaben_03.jpg";
import img_04 from "./../images/MeineAufgabenKennen/Aufgaben_04.jpg";
import img_05 from "./../images/MeineAufgabenKennen/Aufgaben_05.jpg";
import links from "./links";

const TilesData: TileData[] = [
    {
        title: "Mitarbeitende gewinnen",
        link: links["Mitarbeiter gewinnen"],
        external: false,
        description: `
            Spezialisten für den gemeinsamen Erfolg gesucht. Entdecken Sie unsere betrieblichen Rekrutierungsmethoden und finden Sie passionierte Fachkräfte für jedes Terrain.
        `,
    },
    {
        title: "Mitarbeitende onboarden",
        link: links['Mitarbeiter onboarden'],
        external: false,
        description: `
            Der erste Schritt auf dem gemeinsamen Weg.
            Mit unserem systematischen Onboarding
            bereiten Sie neue Mitarbeitende optimal auf
            ihre Rolle vor und schaffen eine solide Basis
            für einen reibungslosen Start.
        `,
    },
    {
        title: "Arbeitssicherheit",
        link: "https://steel-net.thyssenkrupp-steel.com/de/organisation/safety-security-se-ss/arbeitssicherheit",
        external: true,
        description: `
            Fester Stand in jeder Lage. Sorgen Sie für eine sichere
            Arbeitsumgebung und minimieren Sie Risiken. Informieren
            Sie sich hier über die neuesten Standards, Schulungen
            und Präventionsangebote.
        `
    },
    {
        title: "Gut kommunizieren",
        link: links["Gut kommunizieren"],
        external: false,
        description: `
        Gute Kommunikation zeichnet sich durch
        Klarheit, Verständnis und Empathie aus.
        Sie ist der Schlüssel für effektive
        Zusammenarbeit, klare Arbeitsabläufe und
        ein harmonisches Arbeitsklima.
        `
    },
    {
        title: "Rechtssicher handeln",
        link: "https://steel-net.thyssenkrupp-steel.com/de/infos-kampagnen/direx",
        external: true,
        description: `
            Den richtigen Pfad einschlagen. Neue
            Pflichten zur Arbeitssicherheit, zum
            Mutterschutz, im Umweltrecht oder in anderen
            Rechtsgebieten: Ein Unternehmen unserer
            Größe muss viele Gesetze beachten.
        `
    },
    {
        title: "Hybride Arbeit gestalten",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/rund-ums-arbeiten/hybrides-arbeiten-bei-steel/hybrides-arbeiten-erfolgreich-gestalten",
        external: true,
        description: `
        Hybride (Zusammen-) Arbeit ist ein Teil der heutigen Arbeitswelt, sie muss jedoch bewusst gestaltet werden. Das genaue „Wie“ ist von Team zu Team unterschiedlich – entscheidend ist das gemeinsame Verständnis. Erarbeiten Sie mit unserer Unterstützung die für ihr Team am besten passende Lösung.
        `
    },
    {
        title: "Mitarbeitende offboarden",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/ausstieg",
        external: true,
        description: `
            Abschied nehmen, um neue Wege zu
            beschreiten. Sichern Sie Wissen und
            erleichtern Sie den Übergang, während Sie
            die Zusammenarbeit beenden. Mit unseren
            bewährten Offboarding-Strategien
            hinterlassen Sie einen positiven Eindruck.
        `
    },
    {
        title: "Shopfloormanagement",
        link: "https://steel-net.thyssenkrupp-steel.com/de/organisation/produktion/produktionsmanagement/shopfloormanagement",
        external: true,
        description: `
            Unser Ziel ist es die Leistung und den
            Betrieb auf der Produktions-Ebene
            kontinuierlich zu optimieren. Dafür werden
            klare Strukturen und Prozesse benötigt, um
            eine effektive Kommunikation, Koordination
            und Kontrolle zu gewährleisten.
        `
    },
    {
        title: "Qualifizierungsplanung durchführen",
        link: "https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/weiterentwicklung-qualifizierung/qualifizierungsplanung",
        external: true,
        description: `
            Als Führungskraft haben Sie die Möglichkeit Ihren Mitarbeitenden optimale Rahmenbedingungen zu bieten, um sich weiterzuentwickeln und die Team- bzw. Schichtleistung somit zu verbessern.
        `
    },
    {
        title: "Lernangebote",
        link: "https://wd3.myworkday.com/steeleurope/learning",
        external: true,
        description: `
            Ihr Kompass für ständiges Wachstum.
            Von digitalen eLearnings und Webinaren zu
            Präsenztrainings. Wir bieten Ihnen ein
            breites Angebot, um Sie auf Ihrer
            Führungsreise zu unterstützen.
        `
    }
];

export const SearchLinks: SearchItem[] = [
    {
        title: "Aufgaben kennen",
        link: links["meine Aufgaben kennen"],
        description: "Jeder einzelne Schritt zählt, wenn man den Gipfel erreichen will.",
        type: SearchItemType.InternalLink
    },
    ...TilesData.map(tileData => makeTileDataToSearchItem(tileData))
];

export default function MeineAufgabenKennen(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <HeroImage />
            <ContainerCenter>
                <Tiles />
            </ContainerCenter>
        </BasicLayout>
    );
}

function HeroImage() {
    return (
        <section className="mb-8" >
            <HeroIchMöchte image={img_Hero} bgClassName="bg-tk-orange">
                <HeroIchMöchte.Headline className="text-white">Ich möchte: Aufgaben kennen</HeroIchMöchte.Headline>
                <HeroIchMöchte.Header className="text-white">
                    Jeder einzelne Schritt zählt,<br />
                    wenn man den Gipfel erreichen will.
                </HeroIchMöchte.Header>
                <HeroIchMöchte.Body className="text-white">
                    Sortieren Sie den Berg aus Verantwortlichkeiten und Aufgaben, um Übersicht zu <br />
                    gewinnen. So können Sie bewusst und zielorientiert voranschreiten.<br />
                    Nutzen Sie dabei unsere Erfahrung, um Ihren Weg mit Fokus und Weitblick zu planen.
                </HeroIchMöchte.Body>
                <HeroIchMöchte.BlueBody>
                    Eine klare Routenplanung bringt Ihnen dabei die nötige Sicherheit,
                    Hindernisse zu überwinden und flexible Lösungen<br />zu finden.
                </HeroIchMöchte.BlueBody>
            </HeroIchMöchte>
        </section>
    )
}

function Tiles() {
    return (
        <Section>
            <div className="grid grid-cols-12 gap-0">
                <Tile image={img_01} />
                <Tile className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[0].link}
                    external={TilesData[0].external}
                    title={TilesData[0].title}
                >
                    {TilesData[0].description}
                </Tile>
                <Tile className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[1].link}
                    external={TilesData[1].external}
                    title={TilesData[1].title}
                >
                    {TilesData[1].description}
                </Tile>
                {/*  */}
                <Tile className="bg-tk-blue-medium hover:bg-tk-blue-medium-hover"
                    linkTo={TilesData[2].link}
                    external={TilesData[2].external}
                    title={TilesData[2].title}
                >
                    {TilesData[2].description}
                </Tile>
                <Tile image={img_02} />
                <Tile className="bg-tk-blue-light hover:bg-tk-blue-light-hover"
                    linkTo={TilesData[3].link}
                    external={TilesData[3].external}
                    title={TilesData[3].title}
                >
                    {TilesData[3].description}
                </Tile>
                {/*  */}
                <Tile image={img_03} />
                <Tile className="bg-tk-green-dark hover:bg-tk-green-dark-hover"
                    linkTo={TilesData[4].link}
                    external={TilesData[4].external}
                    title={TilesData[4].title}
                >
                    {TilesData[4].description}
                </Tile>
                <Tile className="bg-tk-purple hover:bg-tk-purple-hover"
                    linkTo={TilesData[5].link}
                    external={TilesData[5].external}
                    title={TilesData[5].title}
                    linkTo2="https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=91e78955-fdc8-4069-88a9-28c9a0803af0"
                    external2={true}
                    linkTo2Text="Meetingkultur bei Steel"
                >
                    {TilesData[5].description}
                </Tile>
                {/*  */}
                <Tile className="bg-tk-orange hover:bg-tk-orange-hover"
                    linkTo={TilesData[6].link}
                    external={TilesData[6].external}
                    title={TilesData[6].title}
                >
                    {TilesData[6].description}
                </Tile>
                <Tile className="bg-tk-blue-light hover:bg-tk-blue-light-hover"
                    linkTo={TilesData[7].link}
                    external={TilesData[7].external}
                    title={TilesData[7].title}
                >
                    {TilesData[7].description}
                </Tile>
                <Tile image={img_04} />
                {/*  */}
                <Tile className="bg-tk-brand hover:bg-tk-brand-hover"
                    linkTo={TilesData[8].link}
                    external={TilesData[8].external}
                    title={TilesData[8].title}
                >
                    {TilesData[8].description}
                </Tile>
                <Tile image={img_05} />
                <Tile className="bg-tk-purple hover:bg-tk-purple-hover"
                    linkTo={TilesData[9].link}
                    external={TilesData[9].external}
                    title={TilesData[9].title}
                >
                    {TilesData[9].description}
                </Tile>
            </div>
        </Section>
    );
}

