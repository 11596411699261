import img_logo from '../images/TK-logo-blue-bg.png';
import img_mic from '../images/mic.png';

export default function Podcast(props: { title: string; link: string; }) {
    return (
        <div className="w-full">
            <div className="flex flex-col items-center py-6 bg-tk-brand">
                <img src={img_logo} className='mb-4 w-14' alt="Logo" />
                <span className='block text-xs text-white'>Audio Podcast: {props.title}</span>
            </div>
            <div className='px-5 py-3 mt-3 bg-no-repeat bg-tk-grey-light text-tk-brand bg-[length:auto_75px] bg-[position:right_5px_bottom_5px]' style={{
                backgroundImage: `url(${img_mic})`,
            }}>
                <div className='text-xs'>Audio Podcast:</div>
                <div className='pb-1 text-xl'>
                    {props.title}
                </div>
                <a href={props.link} className='text-xs underline' target="_blank" rel="noreferrer"> Jetzt abspielen</a>
            </div>
        </div>
    );
}
