export default function TKFooter(props: {}): JSX.Element {

    return (
        <footer className="flex flex-col-reverse items-center justify-between px-4 py-4 text-white lg:flex-row lg:h-16 lg:py-0 bg-tk-brand font-tk-regular">
            <p className="mt-4 text-white lg:mt-0">
                Copyright by thyssenkrupp Steel Europe AG@2023-2024
            </p>
            <p className="text-sm text-center lg:text-right">
                Wir freuen uns über Ihre Anregungen und Feedback zum Führungskompass. Schreiben Sie uns dazu eine <a href="mailto:Fuehrungskompass@steeleurope.com" target="_blank" className="text-white underline" rel="noreferrer">E-Mail.</a>
            </p>
        </footer>
    );
}